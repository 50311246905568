import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import { DEFAULT_HOLD_REASONS } from '../constants/Constants';

class PlaceOrderOnHold extends Component {
    constructor(props) {
        super(props);

        this.holdReasons = [... DEFAULT_HOLD_REASONS, "Other"];

        let holdReason = "";
        let otherHoldReason = "";
        if(props.holdReason){
            if(DEFAULT_HOLD_REASONS.indexOf(props.holdReason) >= 0){
                holdReason = props.holdReason;
            }
            else{
                holdReason = "Other";
                otherHoldReason = props.holdReason;
            }
        }
        
        this.state = {
            holdReason: holdReason,
            holdReleaseDate: props.holdReleaseDate ? props.holdReleaseDate : "",
            otherHoldReason: otherHoldReason
        };

        this.handleHoldReasonChange = this.handleHoldReasonChange.bind(this);
        this.handleHoldRelaseDateChange = this.handleHoldRelaseDateChange.bind(this);
        this.handleOtherHoldReasonChange = this.handleOtherHoldReasonChange.bind(this);

        this.notifyChange();
    }

    handleHoldReasonChange(e) {
        this.setState({
            holdReason: e.currentTarget.value
        }, () => {
            this.notifyChange();
        });
    }

    handleHoldRelaseDateChange(value) {
        this.setState({
            holdReleaseDate: value
        }, () => {
            this.notifyChange();
        });

    }

    handleOtherHoldReasonChange(e) {
        this.setState({
            otherHoldReason: e.currentTarget.value
        }, () => {
            this.notifyChange();
        });
    }

    notifyChange() {
        if (this.props.onChange) {
            this.props.onChange({
                holdReason: this.state.holdReason === "Other" ? this.state.otherHoldReason : this.state.holdReason,
                holdReleaseDate: this.state.holdReleaseDate
            });
        }
    }

    render() {
        return (
            <div className="form-row">
                <div className="form-check col-md-12">
                    <label>Reason</label>
                    <br />
                    {this.holdReasons.map(reason =>
                        <React.Fragment key={reason}>
                            <label className="form-radio-label">
                                <input className="form-radio-input" type="radio" name="holdReason" value={reason} checked={this.state.holdReason === reason} onChange={this.handleHoldReasonChange}></input>
                                <span className="form-radio-sign" style={{ marginLeft: "4px", fontWeight: "normal" }}>{reason}</span>
                            </label>
                            <br />
                        </React.Fragment>
                    )}
                    {/* <label className="form-radio-label">
                        <input className="form-radio-input" type="radio" name="otherHoldReason" checked={this.state.otherHoldReason} onChange={this.handleOtherHoldReasonChange}></input>
                        <span className="form-radio-sign" style={{ marginLeft: "4px", fontWeight: "normal" }}>Other</span>
                    </label>
                    <br /> */}
                </div>
                {this.state.holdReason === "Other" &&
                    <div className="form-group col-md-12">
                        <label>Other Reason</label>
                        <input type="text" maxLength={100} className="form-control" value={this.state.otherHoldReason} onChange={this.handleOtherHoldReasonChange}></input>
                    </div>
                }
                <div className="form-group col-md-12">
                    <label>Expected Release</label>
                    <DatePicker className="form-control" selected={this.state.holdReleaseDate} onChange={this.handleHoldRelaseDateChange} />
                </div>
            </div>
        );
    }
}

export default PlaceOrderOnHold
