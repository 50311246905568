import React, { Component } from 'react';
import { HttpRequestService } from '../../services/HttpRequestService';
import CustomerAccountInformation from './CustomerAccountInformation';

class AddCustomer extends Component {
    constructor(props) {
        super(props);
        this.http = new HttpRequestService();
        this.state = {
            accountInformation: {}
        };
        this.handleAccountInfoChange = this.handleAccountInfoChange.bind(this);
        this.createCustomer = this.createCustomer.bind(this);
    }

    handleAccountInfoChange(value) {
        this.setState({
            accountInformation: value
        })
    }

    createCustomer() {
        this.http.get('/api/Customers/CreateCustomer', { model: JSON.stringify(this.state.accountInformation) }).then(data => {
            this.props.history.push('/customer/' + data);
        });
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Add Customer</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card full-height">
                            <div className="card-header">
                                <div className="card-title">General Account Information</div>
                            </div>
                            <div className="card-body">
                                <CustomerAccountInformation mode="addCustomer" onChange={this.handleAccountInfoChange}></CustomerAccountInformation>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card full-height">
                            <div className="card-body pull-right">
                                <button className="btn btn-primary btn-default pull-right mr-2" onClick={this.createCustomer}>Create Customer</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddCustomer;
