import React, { Component } from 'react';
import UserInformation from './UserInformation';
import { HttpRequestService } from '../../services/HttpRequestService';
import { UtilsService } from '../../services/UtilsService';
import Modal from '../../common/Modal';
import RoleSelect from '../../common/RoleSelect';

class EditUser extends Component {
    constructor(props) {
        super(props);
        this.http = new HttpRequestService();
        this.utils = new UtilsService();
        this.state = {
            user: null,
            roles: [],
            selectedRole: -1,
            password: "",
            pin: "",
        }
        this.handleUserInformationChange = this.handleUserInformationChange.bind(this);
        this.handleRoleChange = this.handleRoleChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handlePinChange = this.handlePinChange.bind(this);
        this.updateUser = this.updateUser.bind(this);
        this.addRole = this.addRole.bind(this);
        this.removeRole = this.removeRole.bind(this);
        this.changePassword = this.changePassword.bind(this);
        this.changePin = this.changePin.bind(this);
    }

    componentDidMount() {
        this.getUser();
    }

    handleUserInformationChange(userInformation) {
        this.userInformation = userInformation;
    }

    handleRoleChange(role) {
        this.setState({
            selectedRole: role
        });
    }

    handlePasswordChange(e) {
        this.setState({
            password: e.target.value
        })
    }

    handlePinChange(e) {
        this.setState({
            pin: e.target.value
        })
    }

    getUser() {
        var params = {
            userId: this.props.match.params.id
        }
        this.http.get('/api/Users/GetUser', params).then(data => {
            this.updateState(data);
        })
    }

    updateUser() {
        var params = this.userInformation;
        this.http.get('/api/Users/UpdateUser', { model: JSON.stringify(params) }).then(data => {
            this.utils.success("");
        });
    }

    addRole() {
        var params = {
            userId: this.props.match.params.id,
            role: this.state.selectedRole
        }

        if (this.state.selectedRole !== -1) {
            this.http.get('/api/Users/AddRole', params).then(data => {
                window.$("#addRoleModal").modal("hide");
                this.updateState(data);
            });
        }
    }

    removeRole(role) {
        var params = {
            userId: this.props.match.params.id,
            role: role
        }
        this.http.get('/api/Users/RemoveRole', params).then(data => {
            this.updateState(data);
        });
    }

    changePassword() {
        var params = {
            userId: this.props.match.params.id,
            password: this.state.password
        }
        this.http.get('/api/Users/ChangePassword', params).then(data => {
            this.utils.success("Password is reset to: " + data);
        });
    }

    changePin() {
        var params = {
            userId: this.props.match.params.id,
            pin: this.state.pin
        }
        this.http.post('/api/Users/ChangePin', params).then(data => {
            this.utils.success("PIN is reset to: " + this.state.pin);
        });
    }

    updateState(data) {
        this.setState({
            user: data,
            roles: this.utils.dictToArray(data.roles)
        });
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Edit User</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="card-title">User Information</div>
                            </div>
                            <div className="card-body">
                                {this.state.user &&
                                    <UserInformation user={this.state.user} updating={true} onChange={this.handleUserInformationChange}></UserInformation>
                                }
                            </div>
                            <div className="card-footer">
                                <button className="btn btn-primary btn-default pull-right mr-2" onClick={this.updateUser}>Update</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <div className="card full-height">
                            <div className="card-header">
                                <div className="card-head-row">
                                    <div className="card-title">User Roles</div>
                                    <div className="card-tools">
                                        <button className="btn btn-info btn-border btn-round btn-sm mr-2" data-toggle="modal" data-target="#addRoleModal">
                                            <span className="btn-label">
                                                <i className="fas fa-plus"></i>
                                            </span>
                                            Add
											</button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="form-row">
                                    <table className="table table-striped table-bordered mt-3">
                                        <thead>
                                            <tr>
                                                <th scope="col">Role</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.roles.map(item =>
                                                <tr key={item}>
                                                    <td>{item}</td>
                                                    <td><button className="btn btn-sm btn-danger" onClick={(e) => this.removeRole(item, e)}><i className="fas fa-trash"></i></button></td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <Modal id="addRoleModal" title="Add Role" onOk={this.addRole}>
                                    <div className="form-row">
                                        <div className="form-group col-md-12">
                                            <label htmlFor="site">Role</label>
                                            <RoleSelect value={this.state.selectedRole} onChange={this.handleRoleChange}></RoleSelect>
                                        </div>
                                    </div>
                                </Modal>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card full-height">
                            <div className="card-header">
                                <div className="card-head-row">
                                    <div className="card-title">Change Password</div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="form-row">
                                    <div className="form-group col-md-12">
                                        <label>New password</label>
                                        <input type="password" className="form-control" value={this.state.password} onChange={this.handlePasswordChange}></input>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-12">
                                        <button type="button" className="btn btn-default pull-right" onClick={this.changePassword}>Change Password</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card full-height">
                            <div className="card-header">
                                <div className="card-head-row">
                                    <div className="card-title">Change PIN</div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="form-row">
                                    <div className="form-group col-md-12">
                                        <label>New PIN</label>
                                        <input type="password" className="form-control" value={this.state.pin} onChange={this.handlePinChange}></input>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-12">
                                        <button type="button" className="btn btn-default pull-right" onClick={this.changePin}>Change PIN</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default EditUser
