import React, { Component } from 'react';
import ShoppingCart from './ShoppingCart'
import { HttpRequestService } from '../../services/HttpRequestService';
import moment from 'moment'
import DatePicker from "react-datepicker";
import ShipTypeSelect from '../../common/ShipTypeSelect';
import { UtilsService } from '../../services/UtilsService';
import Checkbox from '../../common/Checkbox';
import Modal from '../../common/Modal';
import OrderInformation from './OrderInformation';
import CustomerAddressList from '../customers/CustomerAddressList';
import CustomerCreditCardList from '../customers/CustomerCreditCardList';
import CustomerInfoCard from '../customers/CustomerInfoCard';
import ComposeEmail from '../../common/ComposeEmail';
import PlaceOrderOnHold from '../../common/PlaceOrderOnHold';
import printJS from 'print-js';

class OrderDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: {},
      customer: {},
      paymentType: null,
      shipType: null,
      shipTypeId: 0,
      shipAmount: 0,
      tax: 0,
      fedexTimeInTransit: "",
      upsTimeInTransit: "",
      uspsTimeInTransit: "",
      sendInvoiceFrom: "",
      sendInvoiceFromDisplayName: "",
      invoiceEmailBody: "",
      shipment: null,
      forecast: [],
    };
    this.http = new HttpRequestService();
    this.utils = new UtilsService();
    this.handleCartItemAdded = this.handleCartItemAdded.bind(this);
    this.handleShipTypeChange = this.handleShipTypeChange.bind(this);
    this.handleShipAmountChange = this.handleShipAmountChange.bind(this);
    this.handleTaxChange = this.handleTaxChange.bind(this);
    this.updateShipping = this.updateShipping.bind(this);
    this.updateTax = this.updateTax.bind(this);
    this.cancelOrder = this.cancelOrder.bind(this);
    this.unCancelOrder = this.unCancelOrder.bind(this);
    this.sendToFulfillment = this.sendToFulfillment.bind(this);
    this.fullFillNoShip = this.fullFillNoShip.bind(this);
    this.handleNoTaxChanged = this.handleNoTaxChanged.bind(this);
    this.handleOrderInformationChange = this.handleOrderInformationChange.bind(this);
    this.updateOrderInformation = this.updateOrderInformation.bind(this);
    this.handleBillingAddressSelect = this.handleBillingAddressSelect.bind(this);
    this.handleShippingAddressSelect = this.handleShippingAddressSelect.bind(this);
    this.handleBillingAddressChange = this.handleBillingAddressChange.bind(this);
    this.handleShippingAddressChange = this.handleShippingAddressChange.bind(this);
    this.handleCreditCardSelect = this.handleCreditCardSelect.bind(this);
    this.handleCreditCardChange = this.handleCreditCardChange.bind(this);
    this.handleInvoiceMailChange = this.handleInvoiceMailChange.bind(this);
    this.sendInvoiceMail = this.sendInvoiceMail.bind(this);
    this.editOrderDate = this.editOrderDate.bind(this);
    this.editShipmentInformation = this.editShipmentInformation.bind(this);
    this.handleOrderDateChange = this.handleOrderDateChange.bind(this);
    this.handleTrackingNumberChange = this.handleTrackingNumberChange.bind(this);
    this.handleTotalWeightChange = this.handleTotalWeightChange.bind(this);
    this.updateOrderDate = this.updateOrderDate.bind(this);
    this.updateShipmentInformation = this.updateShipmentInformation.bind(this);
    this.getInvoiceMailBody = this.getInvoiceMailBody.bind(this);
    this.handleOrderHoldChange = this.handleOrderHoldChange.bind(this);
    this.placeOrderOnHold = this.placeOrderOnHold.bind(this);
    this.validateShipType = this.validateShipType.bind(this);
  }

  componentDidMount() {
    this.getOrder();
    this.getInvoiceMailBody();
    this.getShipmentDetail();
  }

  componentWillUnmount() {
  }

  handleShipTypeChange(shipType) {
    // Check address and ship type match
    this.validateShipType(shipType, true);

    this.setState({
      shipType: shipType,
      shipTypeId: shipType.shipTypeID,
      shipAmount: shipType.cost
    });
  }

  validateShipType(shipType, displayWarning) {
    var valid = true;
    var message = "";
    if (this.state.order.shippingAddress.residential) {
      if (shipType.description === "FEDEX Ground" || shipType.description === "FEDEX Ground N/C") {
        valid = false;
        message = `You selected ${shipType.description} for a residential shipping address. The shipment might be rejected by FedEx.`
      }
    }
    else {
      if (shipType.description === "FEDEX Home Delivery" || shipType.description === "FEDEX Smartpost") {
        valid = false;
        message = `You selected ${shipType.description} for a business shipping address. The shipment might be rejected by FedEx.`
      }
    }

    if (!valid && displayWarning) {
      this.utils.warning(message);
    }
    return { valid: valid, message: message };
  }

  handleShipAmountChange(e) {
    this.setState({ shipAmount: e.target.value });
  }

  handleTaxChange(e) {
    this.setState({ tax: e.target.value });
  }

  getOrder() {
    var params = {
      orderId: this.props.match.params.id
    }
    this.http.get('/api/Orders/GetOrder', params).then(data => {
      if (data.orderID == this.props.match.params.id) {
        this.updateState(data);
        this.getTimeInTransit();
        this.getSmtpAccountId();
      }
      else {
        this.utils.warning("Error: Unable to open this order. Errors have been written to the error log.")
      }
    })
  }

  getTimeInTransit() {
    //don't call this until order info has been returned because we need the ship to address info
    this.getFedexTimeInTransit();
    this.getUPSTimeInTransit();
    this.getUSPSTimeInTransit();
  }

  getInvoiceMailBody() {
    this.http.get('/api/Admin/GetSettings').then(data => {
      var invoiceEmailBody = "";
      if (data.find(s => s.name === "InvoiceEmailBody")) {
        invoiceEmailBody = data.find(s => s.name === "InvoiceEmailBody").value;
      }
      this.setState({
        invoiceEmailBody: invoiceEmailBody,
      });
    })
  }

  getSmtpAccountId() {
    this.http.getSilent('/api/Admin/GetCompanyEmailSendingAccounts', null).then(data => {
      const account = data.find(a => a.companyId == this.state.order.company.key);
      this.setState({
        smtpAccountId: account.smtpAccountId
      })
    });
  }

  getFedexTimeInTransit() {
    var params = {
      orderId: this.props.match.params.id
    }
    this.http.getSilent('/api/TimeInTransit/GetFedexTimeInTransit', params).then(data => {
      this.setState({
        fedexTimeInTransit: data + " business days"
      });
    });
  }

  getUSPSTimeInTransit() {
    var params = {
      countryCode: this.state.order.shippingAddress.country.abbreviation,
      stateCode: this.state.order.shippingAddress.state.abbreviation,
      postalCode: this.state.order.shippingAddress.zipCode
    }
    this.http.getSilent('/api/TimeInTransit/GetUSPSTimeInTransit', params).then(data => {
      this.setState({
        uspsTimeInTransit: data
      });
    });
  }

  getUPSTimeInTransit() {
    var params = {
      countryCode: this.state.order.shippingAddress.country.abbreviation,
      stateCode: this.state.order.shippingAddress.state.abbreviation,
      postalCode: this.state.order.shippingAddress.zipCode
    }
    this.http.getSilent('/api/TimeInTransit/GetUPSTimeInTransit', params).then(data => {
      this.setState({
        upsTimeInTransit: data
      });
    });
  }

  getShipmentDetail() {
    var params = {
      orderId: this.props.match.params.id
    }
    this.http.getSilent('/api/Orders/GetShipment', params).then(data => {
      this.setState({
        shipment: data,
        trackingNumber: data ? data.trackingNumber : "",
        totalWeight: data ? data.totalWeight : 0
      });
    });
  }

  updateState(data) {

    this.setState({
      order: data,
      customer: data.customer,
      paymentType: this.getPaymentType(data),
      creditCard: this.getCreditCard(data),
      shipTypeId: data.orderShipment.shipType !== null ? data.orderShipment.shipType.shipTypeID : -1,
      shipAmount: data.orderShipment.amount,
      tax: data.tax,
      orderDate: moment(data.orderDate).toDate(),
    });

    this.validateCharges(data);
  }

  validateCharges(data) {
    var paymentType = this.getPaymentType(data);
    if (paymentType && paymentType.description === "No Charge") {
      if (data.total !== 0 || data.tax !== 0 || data.taxShipped !== 0 || data.totalPayment !== 0 || data.totalShipped !== 0) {
        this.utils.warning("This customer’s terms are currently set to No Charge and a charge is being attempted.  Please change the appropriate fields to correct error.");
      }
    }
  }

  getPaymentType(order) {
    var payments = order.payments;
    if (payments != null) {
      var props = Object.keys(payments);
      if (props.length > 0) {
        return order.payments[props[0]];
      }
    }
    return null;
  }

  getCreditCard(order) {
    var payments = this.utils.dictToArray(order.payments);
    if (payments != null && payments.length > 0) {
      for (var i = 0; i < payments.length; i++) {
        if (payments[i].paymentMethod.creditCardID) {
          return payments[i].paymentMethod;
        }
      }
    }
    return null;
  }

  handleCartItemAdded(order) {
    this.updateState(order);
  }

  handleOrderInformationChange(orderInformation) {
    this.orderInformation = orderInformation;
  }

  updateShipping() {
    var params = {
      orderId: this.state.order.orderID,
      shipTypeId: this.state.shipTypeId,
      amount: this.state.shipAmount
    }

    var canUpdateShipping = true;
    var shouldDisplayWarning = false;
    var chargedPaymentTypes = ["Credit Card", "Cash", "Prepaid -- Check", "Prepaid -- Certified Funds", "Cash", "Wire Transfer"];
    if (this.state.order.orderStatusText === "Open" && this.state.paymentType && chargedPaymentTypes.find(p => p === this.state.paymentType.description)) {
      if (this.state.order.orderShipment && parseFloat(this.state.shipAmount) !== this.state.order.orderShipment.amount) {
        canUpdateShipping = false;
      }
    }

    if (this.state.order.orderStatusText === "Open") {
      if (this.state.order.orderShipment && parseFloat(this.state.shipAmount) !== this.state.order.orderShipment.amount) {
        shouldDisplayWarning = true;
      }
    }

    if (canUpdateShipping) {
      this.http.get('/api/Orders/UpdateShipping', params).then(data => {
        this.updateState(data);
        if (shouldDisplayWarning) {
          this.utils.warning("Warning: You've made a change that impacts cost to an order already sent to fulfilment. Please make needed updates.");
        }
      })
    }
    else {
      this.utils.warning("This order has already been charged.  Please note that the total shipping cost needs to remain the same.");
    }
  }

  updateTax() {
    var params = {
      orderId: this.state.order.orderID,
      amount: this.state.tax
    }
    this.http.get('/api/Orders/UpdateTax', params).then(data => {
      this.updateState(data);
    })
  }

  cancelOrder() {
    var params = {
      orderId: this.state.order.orderID,
    }
    this.http.get('/api/Orders/CancelOrder', params).then(data => {
      this.updateState(data);
    })
  }

  unCancelOrder() {
    var params = {
      orderId: this.state.order.orderID,
    }
    this.http.get('/api/Orders/UnCancelOrder', params).then(data => {
      this.updateState(data);
    });
  }

  fullFillNoShip() {
    var params = {
      orders: this.state.order.orderID,
    }
    this.http.get('/api/Orders/FulfillOrders', params).then(data => {
      this.getOrder();
    })
  }

  sendToFulfillment(skipValidation) {
    const sendOrderToFulfillment = () => {
      this.http.get('/api/Orders/SendToFulfillment', params).then(data => {
        if (data.success) {
          window.open(this.getPrintPDFUrl("SalesOrder"), "_blank");
          this.updateState(data.order);
        }
        else {
          if (data.failureReason === "AddressValidation") {
            this.utils.confirm(data.message, () => {
              this.sendToFulfillment(true);
            }, null, "Ignore!", "Cancel");
          }
        }
      });
    }

    var params = {
      orderId: this.state.order.orderID,
      validateAddress: !skipValidation
    }

    console.log(params);
    var shipmentValidationResult = this.validateShipType(this.state.order.orderShipment.shipType, false);
    if (shipmentValidationResult.valid) {
      sendOrderToFulfillment();
    }
    else {
      this.utils.confirm(shipmentValidationResult.message, () => {
        sendOrderToFulfillment();
      }, null, "Continue!", "Cancel");
    }
  }

  updateOrderInformation() {
    var params = {
      orderId: this.state.order.orderID,
      companyId: this.orderInformation.companyId,
      accountTypeId: this.orderInformation.accountTypeId,
      orderNote: this.orderInformation.orderNote,
      standardCommentId: this.orderInformation.standardCommentId,
      paymentTypeId: this.orderInformation.paymentTypeId,
      customerPO: this.orderInformation.customerPO,
      autoShipFreq: this.orderInformation.autoShipFreq,
      autoShipDuration: this.orderInformation.autoShipDuration,
      autoShipStartDate: this.orderInformation.autoShipStartDate,
      currencyId: this.orderInformation.currencyId,
    }
    this.http.get('/api/Orders/UpdateOrderInformation', params).then(data => {
      window.$('#orderInformationModal').modal('hide');
      this.updateState(data);
    })
  }

  cancelButtonVisible() {
    var order = this.state.order;
    return order.isValid && order.orderStatusText !== "Cancel";
  }

  unCancelButtonVisible() {
    var order = this.state.order;
    return order.isValid && order.orderStatusText === "Cancel";
  }

  sendEdiInvoiceButtonVisible() {
    var order = this.state.order;
    return order.isValid && (order.orderStatusText === "Fulfilled" || order.orderStatusText === "Posted");
  }

  sendToFulfillmentButtonVisible() {
    var order = this.state.order;
    if (order.isValid && (order.orderStatusText === "Pending" || order.orderStatusText === "OnHold")) {
      if (order.wpOrderId !== null && order.wpOrderId !== "") {
        return true;
      }
      if (order.shoppingCart != null && !order.customer.onHold && order.shippingAddress != null && order.billingAddress != null) {
        var payments = order.payments;
        if (payments != null) {
          var props = Object.keys(payments);
          for (var i = 0; i < props.length; i++) {
            var payment = payments[props[i]];
            // If payment type is credit card, make sure order has a credit card set
            if (payment.description === "Credit Card" && payment.paymentMethod.creditCardID <= 0) {
              return false;
            }
          }
        }
        return true;
      }
    }
    return false;
  }

  placeOnHoldButtonVisible() {
    var order = this.state.order;
    return order.isValid && (order.orderStatusText === "Pending" || order.orderStatusText === "Open");
  }

  noTaxChkVisible() {
    if (this.state.order != null && this.state.order.isValid && (this.state.order.orderStatusText === "Pending" || this.state.order.orderStatusText === "Open") && this.state.order.salesman != null && this.state.order.salesman.value === "Wholesale") {
      return true;
    }
    return false;
  }

  handleNoTaxChanged(checked) {
    var params = {
      orderId: this.state.order.orderID,
      ignore: checked
    }
    this.http.get('/api/Orders/IgnoreTax', params).then(data => {
      this.updateState(data);
    })
  }

  handleBillingAddressSelect(address) {
    this.selectedBillingAddressId = address.addressID;
    this.handleBillingAddressChange();
  }

  handleShippingAddressSelect(address) {
    this.selectedShippingAddressId = address.addressID;
    this.handleShippingAddressChange();
  }

  handleBillingAddressChange() {
    if (this.selectedBillingAddressId) {
      var params = {
        orderId: this.state.order.orderID,
        addressId: this.selectedBillingAddressId
      }
      this.http.get('/api/Orders/UpdateBillingAddress', params).then(data => {
        window.$('#billingAddressesModal').modal('hide');
        this.updateState(data);
      });
    }
    else {
      window.$('#billingAddressesModal').modal('hide');
    }
  }

  handleShippingAddressChange() {
    if (this.selectedShippingAddressId) {
      var params = {
        orderId: this.state.order.orderID,
        addressId: this.selectedShippingAddressId
      }
      this.http.get('/api/Orders/UpdateShippingAddress', params).then(data => {
        window.$('#shippingAddressesModal').modal('hide');
        this.updateState(data);
        this.getFedexTimeInTransit();
        this.getUPSTimeInTransit();
        this.getUSPSTimeInTransit();
      });
    }
    else {
      window.$('#shippingAddressesModal').modal('hide');
    }
  }

  handleCreditCardSelect(card) {
    this.selectedCardId = card.creditCardID;
  }

  handleCreditCardChange() {
    if (this.selectedCardId) {
      var params = {
        orderId: this.state.order.orderID,
        cardId: this.selectedCardId
      }
      this.http.get('/api/Orders/UpdateCreditCard', params).then(data => {
        window.$('#creditCardsModal').modal('hide');
        this.updateState(data);
      });
    }
    else {
      window.$('#creditCardsModal').modal('hide');
    }
  }

  handleInvoiceMailChange(mail) {
    this.invoiceMail = mail;
  }

  sendInvoiceMail() {
    if (this.invoiceMail) {
      var model = this.invoiceMail;
      model.orderId = this.state.order.orderID;
      this.http.post('/api/Orders/SendInvoiceMail', model).then(data => {
        this.utils.success("");
        window.$('#sendInvoiceModal').modal('hide');
      });
    }
    else {
      window.$('#sendInvoiceModal').modal('hide');
    }
  }

  getPrintPDFUrl(type) {
    return this.http.HOST + "/Print/Order?type=" + type + "&orderID=" + this.state.order.orderID + "&orderNumber=" + this.state.order.orderNumber;
  }

  getPrintUCCLabelUrl() {
    return this.http.HOST + "/Print/UCCLabel?OrderId=" + this.state.order.orderID + "&OrderNumber=" + this.state.order.orderNumber;
  }

  editOrderDate() {
    window.$("#editOrderDateModal").modal("show");
  }

  editShipmentInformation() {
    window.$("#editShipmentInformationModal").modal("show");
  }

  handleOrderDateChange(date) {
    this.setState({
      orderDate: date
    });
  }

  handleTrackingNumberChange(e) {
    this.setState({
      trackingNumber: e.target.value
    });
  }

  handleTotalWeightChange(e) {
    this.setState({
      totalWeight: e.target.value
    });
  }

  updateOrderDate() {
    var params = {
      orderId: this.state.order.orderID,
      orderDate: this.state.orderDate
    }
    this.http.get('/api/Orders/UpdateOrderDate', params).then(data => {
      window.$("#editOrderDateModal").modal("hide");
      this.updateState(data);
    })
  }

  updateShipmentInformation() {
    var params = {
      trackingNumber: this.state.trackingNumber,
      totalWeight: this.state.totalWeight,
      orderId: this.state.order.orderID
    }
    this.http.post('/api/Orders/UpdateShipmentInformation', params).then(data => {
      window.$("#editShipmentInformationModal").modal("hide");
      this.getShipmentDetail();
    })
  }

  sendEDIInvoice() {
    this.http.post('/api/Orders/PrepareEDIInvoice', { id: this.state.order.orderID }).then(data => {
      this.utils.success("");
    });
  }

  sendToGravity() {
    this.http.post('/api/Orders/SendToGravity', { id: this.state.order.orderID }).then(data => {
      this.utils.success("");
    });
  }

  getEmailSubject() {
    let subject = this.state.order.company ? this.state.order.company.value + " Invoice #" + this.state.order.orderNumber : "";
    if (this.state.order.po !== null && this.state.order.po !== "") {
      subject += " / PO #" + this.state.order.po;
    }
    return subject;
  }

  openOrderOnHoldModal() {
    window.$("#placeOrderOnHoldModal").modal("show");
  }

  handleOrderHoldChange(val) {
    this.orderHold = val;
  }

  placeOrderOnHold(skipAddressValidation) {
    var params = {
      orderId: this.state.order.orderID,
      reason: this.orderHold.holdReason,
      releaseDate: this.orderHold.holdReleaseDate,
      validateAddress: !skipAddressValidation
    }

    const placeOnHold = () => {
      this.http.post('/api/Orders/PlaceOnHold', params).then(data => {
        if (data.success) {
          this.updateState(data.order);
          window.$("#placeOrderOnHoldModal").modal("hide");
        }
        else {
          if (data.failureReason === "AddressValidation") {
            this.utils.confirm(data.message, () => {
              this.placeOrderOnHold(true);
            }, null, "Ignore!", "Cancel");
          }
        }
      });
    }

    var shipmentValidationResult = this.validateShipType(this.state.order.orderShipment.shipType, false);
    if (shipmentValidationResult.valid) {
      placeOnHold();
    }
    else {
      this.utils.confirm(shipmentValidationResult.message, () => {
        placeOnHold();
      }, null, "Continue!", "Cancel");
    }
  }

  async checkTemperature() {
    // get lat long
    try {
      this.http.setLoading(true);
      var response = await fetch(`https://api.openweathermap.org/geo/1.0/zip?zip=${this.state.order.shippingAddress.zipCode},US&appid=94c41001a9473e4b4500155c956c3a13`);
      var result = await response.json();
      var gridResponse = await fetch(`https://api.weather.gov/points/${result.lat},${result.lon}`);
      var gridResult = await gridResponse.json();
      var forecastResponse = await fetch(gridResult.properties.forecast);
      var forecastResult = await forecastResponse.json();
      var forecast = [];
      for (let i = 0; i < forecastResult.properties.periods.length; i = i + 2) {
        var day = forecastResult.properties.periods[i];
        var night = forecastResult.properties.periods[i + 1];
        forecast.push({
          date: day.startTime,
          day: day.temperature,
          night: night.temperature,
          dayDescription: day.shortForecast,
          dayIcon: day.icon,
          nightDescription: night.shortForecast,
          nightIcon: night.icon
        });
      };
      var title = "Weather Forecast";
      try {
        title = this.state.order.shippingAddress.city + ", " + this.state.order.shippingAddress.state.abbreviation;
      }
      catch {
      }
      this.setState({
        forecast: forecast,
        forecastTitle: title
      });
      window.$("#temperatureModal").modal("show");
    }
    finally {
      this.http.setLoading(false);
    }
    // var url = `https://www.accuweather.com/search-locations?query=${this.state.order.shippingAddress.zipCode}&target=daily`;  
    // window.open(url, "_blank",
    //   `toolbar=no,scrollbars=yes,resizable=yes,top=${window.innerHeight / 2 - 250},left=${window.innerWidth / 2 - 200},width=400,height=500`)
  }

  printShippingLabels() {
    printJS({
      printable: this.http.HOST + "/Print/FedExShippingLabels?orders=" + this.state.order.orderID,
      type: 'pdf',
      showModal: true,
      modalMessage: "Retrieving Label"
    });
  }

  getTrackingLink(shipment) {
    var shipType = this.state.order.orderShipment.shipType;
    if (shipType.description.toLowerCase().indexOf("fedex") >= 0) {
      return "https://www.fedex.com/fedextrack/?trknbr=" + shipment.trackingNumber;
    }
    else if (shipType.description.toLowerCase().indexOf("ups") >= 0) {
      return "https://www.ups.com/track?loc=en_US&tracknum=" + shipment.trackingNumber;
    }
    else if (shipType.description.toLowerCase().indexOf("usps") >= 0) {
      return "https://tools.usps.com/go/TrackConfirmAction?tLabels=" + shipment.trackingNumber;
    }
    return null;
  }

  render() {
    return (
      <div className={"page-inner " + (this.state.customer && this.state.customer.accountTypeID === 11 ? "page-web-retail" : "")}>
        <div className="page-header">
          <h4 className="page-title">Order Detail</h4>
        </div>
        {/* Rpw 1 */}
        <div className="row">
          {/* Customer Information */}
          <div className="col-md-3">
            <CustomerInfoCard customer={this.state.customer}></CustomerInfoCard>
          </div>
          {/* End Customer Information */}
          <div className="col-md-9">
            <div className="card full-height">
              <div className="card-header">
                <div className="card-head-row">
                  <div className="card-title">Order Information</div>
                  <div className="card-tools">
                    <button className="btn btn-info btn-border btn-round btn-sm mr-2" data-toggle="modal" data-target="#orderInformationModal">
                      <span className="btn-label">
                        <i className="fas fa-pen"></i>
                      </span>
                      EDIT
                    </button>
                    <a className="btn btn-info btn-border btn-round btn-sm mr-2" href={this.getPrintPDFUrl('SalesOrder')} target="_blank" rel="noopener noreferrer">
                      <span className="btn-label">
                        <i className="fa fa-print"></i>
                      </span>
                      SALES ORDER
                    </a>
                    <a className="btn btn-info btn-border btn-round btn-sm mr-2" href={this.getPrintPDFUrl('PackingSlip')} target="_blank" rel="noopener noreferrer">
                      <span className="btn-label">
                        <i className="fa fa-print"></i>
                      </span>
                      PACKING SLIP
                    </a>
                    <a className="btn btn-info btn-border btn-round btn-sm mr-2" href={this.getPrintUCCLabelUrl()} target="_blank" rel="noopener noreferrer">
                      <span className="btn-label">
                        <i className="fa fa-print"></i>
                      </span>
                      UCC LABEL
                    </a>
                    <a className="btn btn-info btn-border btn-round btn-sm mr-2" href={this.getPrintPDFUrl('Invoice')} target="_blank" rel="noopener noreferrer">
                      <span className="btn-label">
                        <i className="fa fa-print"></i>
                      </span>
                      INVOICE
                    </a>
                    <div className="btn-group dropdown">
                      <button type="button" className="btn btn-info btn-border btn-round btn-sm dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                        <span className="btn-label">
                          <i className="fas fa-ellipsis-h"></i>
                        </span>
                      </button>
                      <ul className="dropdown-menu" role="menu" x-placement="bottom-start">
                        <li>
                          <button className="dropdown-item" data-toggle="modal" data-target="#sendInvoiceModal">Email Invoice</button>
                          {this.sendEdiInvoiceButtonVisible() &&
                            <button className="dropdown-item" onClick={(e) => this.sendEDIInvoice()}>Send EDI Invoice</button>
                          }
                          {this.sendEdiInvoiceButtonVisible() &&
                            <button className="dropdown-item" onClick={(e) => this.sendToGravity()}>Send to Gravity</button>
                          }
                        </li>
                      </ul>
                    </div>

                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="d-flex">
                  <div className="col">
                    <h6 className="fw-bold mb-1">ORDER #</h6>
                    <small className="text-muted">{this.state.order.orderNumber}</small>
                  </div>
                  <div className="col">
                    <h6 className="fw-bold mb-1">ORDER DATE</h6>
                    <small className="text-muted">{moment(this.state.order.orderDate).format('l')}</small><small><button className="btn btn-xs btn-link" onClick={this.editOrderDate}><i className="fas fa-pen"></i></button></small>
                  </div>
                  <div className="col">
                    <h6 className="fw-bold mb-1">ACCOUNT TYPE</h6>
                    <small className="text-muted">{this.state.order.salesman != null ? this.state.order.salesman.value : ""} {this.state.order.salesmanInitials}</small>
                  </div>
                </div>
                <div className="separator"></div>
                <div className="d-flex">
                  <div className="col">
                    <h6 className="fw-bold mb-1">COMPANY</h6>
                    <small className="text-muted">{this.state.order.company != null ? this.state.order.company.value : ""}</small>
                  </div>
                  <div className="col-8">
                    <h6 className="fw-bold mb-1">ORDER NOTES</h6>
                    <small className="text-muted">{this.state.order.standardComment != null && this.state.order.standardComment.key != null ? this.state.order.standardComment.value + " " + this.state.order.notes : this.state.order.notes}</small>
                  </div>
                  <div className="col">
                    <h6 className="fw-bold mb-1">SHIPPING METHOD</h6>
                    <small className="text-muted">{this.state.order.orderShipment != null && this.state.order.orderShipment.shipType != null ? this.state.order.orderShipment.shipType.description : ""}</small>
                  </div>
                </div>
                <div className="separator"></div>
                <div className="d-flex">
                  <div className="col">
                    <h6 className="fw-bold mb-1">CUSTOMER PO</h6>
                    <small className="text-muted">{this.state.order.po}</small>
                  </div>
                  <div className="col">
                    <h6 className="fw-bold mb-1">PAYMENT TYPE</h6>
                    <small className="text-muted">{this.state.paymentType != null ? this.state.paymentType.description : ""}</small>
                  </div>
                  <div className="col">
                    <h6 className="fw-bold mb-1">CURRENCY</h6>
                    <small className="text-muted">{this.state.order.currency != null ? this.state.order.currency.value : ""}</small>
                  </div>
                </div>
                <div className="separator"></div>
                <div className="d-flex">
                  <div className="col">
                    <h6 className="fw-bold mb-1">CURRENT STATUS</h6>
                    <small className="text-muted">{this.state.order.orderStatusText}</small>
                  </div>
                  <div className="col">
                    <h6 className="fw-bold mb-1">SITE</h6>
                    <small className="text-muted">{this.state.order.site != null ? this.state.order.site.value : ""}</small>
                  </div>
                  <div className="col">
                    <h6 className="fw-bold mb-1">PARENT ORDER #</h6>
                    <small className="text-muted">{this.state.order.parentOrder != null ? this.state.order.parentOrder.orderNumber : ""}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal id="orderInformationModal" title="Order Information" onOk={this.updateOrderInformation}>
            {this.state.order.company &&
              <OrderInformation
                accountType={this.state.order.salesman.key}
                orderNote={this.state.order.notes}
                standardComment={this.state.order.standardComment.key}
                paymentType={this.state.paymentType.paymentMethod.key}
                customerPO={this.state.order.po}
                company={this.state.order.company.key}
                currency={this.state.order.currency.key}
                autoShip={this.state.order.autoShip}
                onChange={this.handleOrderInformationChange}
              >
              </OrderInformation>
            }
          </Modal>
        </div>
        {/* End Row 1*/}
        {/* Row 2 */}
        <div className="row">
          {/* Billing Address */}
          <div className="col-md-3">
            <div className="card full-height">
              <div className="card-header">
                <div className="card-head-row">
                  <div className="card-title">Billing Address</div>
                  <div className="card-tools">
                    <button className="btn btn-info btn-border btn-round btn-sm mr-2" data-toggle="modal" data-target="#billingAddressesModal">
                      <span className="btn-label">
                        <i className="fas fa-pen"></i>
                      </span>
                      EDIT
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="d-flex">
                  <div className="col">
                    <p dangerouslySetInnerHTML={{ __html: this.state.order.billingAddress != null ? this.state.order.billingAddress.formattedAddress : "" }}>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal id="billingAddressesModal" title="Select Billing Address">
            <CustomerAddressList onSelect={this.handleBillingAddressSelect} customer={this.state.customer} type="billing" selectedAddressId={this.state.order.billingAddress ? this.state.order.billingAddress.addressID : 0}></CustomerAddressList>
          </Modal>
          {/* End Billing Address */}
          {/* Shipping Address */}
          <div className="col-md-3">
            <div className="card full-height">
              <div className="card-header">
                <div className="card-head-row">
                  <div className="card-title">Shipping Address</div>
                  <div className="card-tools">
                    <button className="btn btn-info btn-border btn-round btn-sm mr-2" data-toggle="modal" data-target="#shippingAddressesModal">
                      <span className="btn-label">
                        <i className="fas fa-pen"></i>
                      </span>
                      EDIT
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="d-flex">
                  <div className="col">
                    <p dangerouslySetInnerHTML={{ __html: this.state.order.shippingAddress != null ? this.state.order.shippingAddress.formattedAddress : "" }}>
                    </p>
                    {this.state.order.shippingAddress && this.state.order.shippingAddress.residential &&
                      <h1 style={{ position: "absolute", top: 0, right: 8 }}>R</h1>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal id="shippingAddressesModal" title="Select Shipping Address">
            <CustomerAddressList onSelect={this.handleShippingAddressSelect} customer={this.state.customer} type="shipping" selectedAddressId={this.state.order.shippingAddress ? this.state.order.shippingAddress.addressID : 0}></CustomerAddressList>
          </Modal>
          {/* End Shipping Address */}
          {/* Credit Card */}
          {this.state.paymentType && this.state.paymentType.description === "Credit Card" &&
            <React.Fragment>
              <div className="col-md-3">
                <div className="card full-height">
                  <div className="card-header">
                    <div className="card-head-row">
                      <div className="card-title">Credit Card</div>
                      <div className="card-tools">
                        <button className="btn btn-info btn-border btn-round btn-sm mr-2" data-toggle="modal" data-target="#creditCardsModal">
                          <span className="btn-label">
                            <i className="fas fa-pen"></i>
                          </span>
                          EDIT
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="d-flex">
                      <div className="col">
                        <p dangerouslySetInnerHTML={{ __html: this.state.creditCard ? this.state.creditCard.formattedCreditCard : "" }}>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Modal id="creditCardsModal" title="Select Credit Card" onOk={this.handleCreditCardChange}>
                <CustomerCreditCardList onSelect={this.handleCreditCardSelect} customer={this.state.customer} selectedCardId={this.state.creditCard ? this.state.creditCard.creditCardID : 0}></CustomerCreditCardList>
              </Modal>
            </React.Fragment>
          }
          {/* End Credit Card */}
        </div>
        {/* End Row 2 */}
        {/* Row 3 */}
        <div className="row">
          {/* Products */}
          <div className="col-md-12">
            <div className="card full-height">
              <div className="card-header">
                <div className="card-head-row">
                  <div className="card-title">Products</div>
                </div>
              </div>
              <div className="card-body">
                {this.state.order.shoppingCart != null &&
                  <ShoppingCart order={this.state.order} shoppingCart={this.state.order.shoppingCart} onItemAdded={this.handleCartItemAdded}></ShoppingCart>
                }
                {this.noTaxChkVisible() &&
                  <Checkbox checked={this.state.order.ignoreTax} onChange={this.handleNoTaxChanged} label="Do not apply tax to this wholesale customer order."></Checkbox>
                }
              </div>
            </div>
          </div>
          {/* End Products */}
        </div>
        {/* End Row 3 */}
        {/* Row 4 */}
        <div className="row">
          {/* Sales Tax */}
          <div className="col-md-3">
            <div className="card full-height">
              <div className="card-header">
                <div className="card-title">Sales Tax</div>
              </div>
              <div className="card-body">
                <div className="form-row">
                  <div className="form-group">
                    <label>Tax Amount</label>
                    <input type="text" value={this.state.tax} onChange={this.handleTaxChange} className="form-control" />
                  </div>
                </div>
                <div className="form-row">
                  <button className="btn btn-primary" type="button" onClick={this.updateTax}>Update Tax</button>
                </div>
              </div>
            </div>
          </div>
          {/* End Sales Tax */}
          {/* Shipping */}
          <div className="col-md-3">
            <div className="card full-height">
              <div className="card-header">
                <div className="card-title">Order Shipping</div>
              </div>
              <div className="card-body">
                <div className="form-row">
                  <div className="form-group">
                    <label>Shipping Type</label>
                    <ShipTypeSelect placeholder="-- Select Shipping --" order={this.state.order} value={this.state.shipTypeId} onChange={this.handleShipTypeChange}></ShipTypeSelect>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <label>Shipping Amount</label>
                    <input type="text" value={this.state.shipAmount} onChange={this.handleShipAmountChange} className="form-control" />
                  </div>
                </div>
                <div className="form-row">
                  <button className="btn btn-primary" type="button" onClick={this.updateShipping}>Update Shipping</button>
                </div>
              </div>
            </div>
          </div>
          {/* End Shipping */}
          {/* Time in Transit */}
          <div className="col-md-3">
            <div className="card full-height">
              <div className="card-header">
                <div className="card-title">Time in Transit</div>
              </div>
              <div className="card-body">
                <div className="d-flex">
                  <div className="col">
                    <h6 className="fw-bold mb-1">FEDEX GROUND</h6>
                    <small className="text-muted">{this.state.fedexTimeInTransit}</small>
                  </div>
                </div>
                <div className="separator"></div>
                <div className="d-flex">
                  <div className="col">
                    <h6 className="fw-bold mb-1">UPS</h6>
                    <small className="text-muted">{this.state.upsTimeInTransit}</small>
                  </div>
                </div>
                <div className="separator"></div>
                <div className="d-flex">
                  <div className="col">
                    <h6 className="fw-bold mb-1">USPS</h6>
                    <small className="text-muted">{this.state.uspsTimeInTransit}</small>
                  </div>
                </div>
                {this.state.order && this.state.order.shippingAddress &&
                  <div className="d-flex mt-4">
                    <div className="col">
                      <button className="btn btn-default" onClick={() => this.checkTemperature()}>Check Temperature</button>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
          {/* End Time in Transit */}
          {/* Shipment */}
          {this.state.order && (this.state.order.orderStatusText === "Fulfilled" || this.state.order.orderStatusText === "Posted") && this.state.shipment !== null &&
            <div className="col-md-3">
              <div className="card full-height">
                <div className="card-header">
                  <div className="card-title">Shipment</div>
                </div>
                <div className="card-body">
                  <div className="d-flex">
                    <div className="col">
                      <h6 className="fw-bold mb-1">Fulfilled by</h6>
                      <small className="text-muted">{this.state.order.fulfilledBy}</small>
                    </div>
                  </div>
                  <div className="separator"></div>
                  <div className="d-flex">
                    <div className="col">
                      <h6 className="fw-bold mb-1">Tracking Number</h6>
                      <small className="text-muted">
                        <a href={this.getTrackingLink(this.state.shipment)} target="_blank">
                          {this.state.shipment.trackingNumber}
                        </a>
                      </small>
                      <small><button className="btn btn-xs btn-link" onClick={this.editShipmentInformation}><i className="fas fa-pen"></i></button></small>
                    </div>
                  </div>
                  <div className="separator"></div>
                  <div className="d-flex">
                    <div className="col">
                      <h6 className="fw-bold mb-1">Total Weight</h6>
                      <small className="text-muted">
                        {this.state.shipment.totalWeight}
                      </small>
                      <small><button className="btn btn-xs btn-link" onClick={this.editShipmentInformation}><i className="fas fa-pen"></i></button></small>
                    </div>
                  </div>
                  <div className="separator"></div>
                  {this.state.order && this.state.order.shippingAddress &&
                    <div className="d-flex mt-4">
                      <div className="col">
                        <button className="btn btn-default" onClick={() => this.printShippingLabels()}>Print Shipping Labels</button>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          }
          {/* End Shipment */}
        </div>
        {/* End Row 4 */}
        {/* Row 5 */}
        <div className="row">
          <div className="col-md-12">
            <div className="card full-height">
              <div className="card-body pull-right">
                {this.sendToFulfillmentButtonVisible() &&
                  <button className="btn btn-primary btn-default pull-right mr-2" onClick={() => this.sendToFulfillment()}>Send to Fulfillment</button>
                }
                {this.placeOnHoldButtonVisible() &&
                  <button className="btn btn-secondary btn-default pull-right mr-2" onClick={this.openOrderOnHoldModal}>Place On Hold</button>
                }
                {this.sendToFulfillmentButtonVisible() &&
                  <button className="btn btn-default pull-left mr-2" onClick={this.fullFillNoShip}>Fulfill, No Ship</button>
                }
                {this.cancelButtonVisible() &&
                  <button className="btn btn-primary btn-danger pull-right mr-2" onClick={this.cancelOrder}>Cancel Order</button>
                }
                {this.unCancelButtonVisible() &&
                  <button className="btn btn-primary btn-danger pull-right mr-2" onClick={this.unCancelOrder}>Un-cancel Order</button>
                }
              </div>
            </div>
          </div>
        </div>
        {/* End Row 5 */}
        <Modal id="sendInvoiceModal" title="Email Invoice" onOk={this.sendInvoiceMail}>
          <ComposeEmail smtpAccountId={this.state.smtpAccountId} to={this.state.customer.invoiceEmailAddresses} onChange={this.handleInvoiceMailChange} subject={this.getEmailSubject()} body={this.state.invoiceEmailBody}></ComposeEmail>
        </Modal>
        <Modal height={350} id="editOrderDateModal" title="Update Order Date" onOk={this.updateOrderDate}>
          <div className="form-row">
            <div className="form-group col-md-12">
              <label>Order Date</label>
              <DatePicker className="form-control" selected={this.state.orderDate} onChange={this.handleOrderDateChange} />
            </div>
          </div>
        </Modal>
        <Modal height={500} id="placeOrderOnHoldModal" title="Order Hold" onOk={this.placeOrderOnHold}>
          <PlaceOrderOnHold onChange={this.handleOrderHoldChange}></PlaceOrderOnHold>
        </Modal>
        <Modal height={350} id="editShipmentInformationModal" title="Update Shipment Information" onOk={this.updateShipmentInformation}>
          <div className="form-row">
            <div className="form-group col-md-12">
              <label>Tracking Number</label>
              <input className="form-control" type="text" value={this.state.trackingNumber} onChange={this.handleTrackingNumberChange} />
            </div>
            <div className="form-group col-md-12">
              <label>Total Weight</label>
              <input className="form-control" type="text" value={this.state.totalWeight} onChange={this.handleTotalWeightChange} />
            </div>
          </div>
        </Modal>
        <Modal id="temperatureModal" title={this.state.forecastTitle} hideButtons={true}>
          <div className="daily-list">
            {this.state.forecast.map(f =>
              <div className="daily-list-item ">
                <div className="date">
                  <p className="day">{moment(f.date).format('ddd')}</p>
                  <p>{moment(f.date).format('MM/DD')}</p>
                </div>
                {/* <img className="icon" height="40" width="40" src={f.dayIcon} /> */}
                <div className="temp-phrase-wrapper">
                  <div className="temp">
                    <span className="temp-hi">{f.day}°</span>
                    <span className="temp-lo">{f.night}°</span>
                  </div>
                </div>
                <div className="phrase">
                  <p className="no-wrap">{f.dayDescription}</p>
                  <span className="night">
                    {/* <img className="night-icon" height="22" width="22" src={f.nightIcon} /> */}
                    <p className="no-wrap">Night: {f.nightDescription}</p>
                  </span>
                </div>
              </div>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

export default OrderDetail;
