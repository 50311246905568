import React, { Component } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import NavMenu from './NavMenu'
import AppRoutes from './AppRoutes';
import ErrorBoundary from './common/ErrorBoundary';
import HeaderNav from './HeaderNav';
import Login from './pages/account/Login';
import { HttpRequestService } from './services/HttpRequestService';
import PinLogin from './pages/account/PinLogin';
import Modal from './common/Modal';
import { SharedState } from './services/SharedState';

class App extends Component {
  navOpen = 1;
  mini_sidebar = 1;
  page_sidebar_open = 1;
  overlay_sidebar_open = 1;
  PIN_TIMEOUT_MINUTES = 30;
  SESSION_TIMEOUT_MINUTES = 240;

  constructor(props) {
    super(props);
    this.http = new HttpRequestService();
    this.state = {
      loggedIn: false,
      loading: true,
      username: localStorage.getItem("username"),
      isLocked: false,
      lockReason: "",
      timestamp: new Date().getTime(),
    }
    this.onLoggedIn = this.onLoggedIn.bind(this);
  }

  componentDidMount() {
    this.http.get('/api/PowerD/IsLoggedIn', null).then(result => {
      this.setState({
        loading: false,
        isLocked: false,
        loggedIn: result,
        username: localStorage.getItem("username"),
      });
      if (result) {
        setTimeout(() => {
          this.preparePage();
        }, 1000);
      }
    });

    SharedState.subscribe("lockedOut", (key, value) => {
      this.setState({
        isLocked: value.locked,
        lockReason: value.reason
      });
    });

    this.timer = setInterval(() => this.checkTimeout(), 10000);
  }

  onLoggedIn() {
    this.setState({
      loggedIn: true,
      username: localStorage.getItem("username"),
      timestamp: new Date().getTime(),
    }, () => {
      setTimeout(() => {
        this.preparePage();
      }, 1000);
    });

    SharedState.setValue("lockedOut", { locked: false });
  }

  checkTimeout() {
    if (this.state.loggedIn) {
      var lastActivityTime = localStorage.getItem("lastRequestTime");
      var currentTime = (new Date()).getTime();
      var idleTimeInMinutes = (currentTime - lastActivityTime) / (1000 * 60);
      if (lastActivityTime === 0) {
        idleTimeInMinutes = 0;
      }

      var loginType = localStorage.getItem("loginType");
      if (loginType === "pin") {
        if (idleTimeInMinutes > this.PIN_TIMEOUT_MINUTES) {
          localStorage.removeItem("auth-token");
          SharedState.setValue("lockedOut", { locked: true, reason: "locked" });
        }
      }
      else {
        if (idleTimeInMinutes > this.SESSION_TIMEOUT_MINUTES) {
          this.http.get('/api/PowerD/Logout', null).then(data => {
            this.http.logout();
          });
        }
      }
    }
  }

  preparePage() {
    var scrollbarDashboard = window.$('.sidebar .scrollbar');
    if (scrollbarDashboard.length > 0) {
      scrollbarDashboard.scrollbar();
    }

    var toggle = window.$('.sidenav-toggler');
    var self = this;
    toggle.click('click', function () {
      if (self.nav_open === 1) {
        window.$('html').removeClass('nav_open');
        toggle.removeClass('toggled');
        this.nav_open = 0;
      } else {
        window.$('html').addClass('nav_open');
        toggle.addClass('toggled');
        self.nav_open = 1;
      }
    });

    var minibutton = window.$('.toggle-sidebar');
    if (window.$('.wrapper').hasClass('sidebar_minimize')) {
      this.mini_sidebar = 1;
      minibutton.addClass('toggled');
      minibutton.html('<i class="icon-options-vertical"></i>');
    }

    minibutton.on('click', function () {
      if (this.mini_sidebar === 1) {
        window.$('.wrapper').removeClass('sidebar_minimize');
        minibutton.removeClass('toggled');
        minibutton.html('<i class="icon-menu"></i>');
        this.mini_sidebar = 0;
      } else {
        window.$('.wrapper').addClass('sidebar_minimize');
        minibutton.addClass('toggled');
        minibutton.html('<i class="icon-options-vertical"></i>');
        this.mini_sidebar = 1;
      }
      window.$(window).resize();
    });

    var pageSidebarToggler = window.$('.page-sidebar-toggler');

    pageSidebarToggler.on('click', function () {
      if (this.page_sidebar_open === 1) {
        window.$('html').removeClass('pagesidebar_open');
        pageSidebarToggler.removeClass('toggled');
        this.page_sidebar_open = 0;
      } else {
        window.$('html').addClass('pagesidebar_open');
        pageSidebarToggler.addClass('toggled');
        this.page_sidebar_open = 1;
      }
    });

    var pageSidebarClose = window.$('.page-sidebar .back');

    pageSidebarClose.on('click', function () {
      window.$('html').removeClass('pagesidebar_open');
      pageSidebarToggler.removeClass('toggled');
      this.page_sidebar_open = 0;
    });


    var overlaybutton = window.$('.sidenav-overlay-toggler');
    if (window.$('.wrapper').hasClass('is-show')) {
      this.overlay_sidebar_open = 1;
      overlaybutton.addClass('toggled');
      overlaybutton.html('<i class="icon-options-vertical"></i>');
    }

    overlaybutton.on('click', function () {
      if (this.overlay_sidebar_open === 1) {
        window.$('.wrapper').removeClass('is-show');
        overlaybutton.removeClass('toggled');
        overlaybutton.html('<i class="icon-menu"></i>');
        this.overlay_sidebar_open = 0;
      } else {
        window.$('.wrapper').addClass('is-show');
        overlaybutton.addClass('toggled');
        overlaybutton.html('<i class="icon-options-vertical"></i>');
        this.overlay_sidebar_open = 1;
      }
      window.$(window).resize();
    });
    window.$('.sidebar').hover(function () {
      if (window.$('.wrapper').hasClass('sidebar_minimize')) {
        window.$('.wrapper').addClass('sidebar_minimize_hover');
      }
    }, function () {
      if (window.$('.wrapper').hasClass('sidebar_minimize')) {
        window.$('.wrapper').removeClass('sidebar_minimize_hover');
      }
    });

    window.$(".nav-item a").on('click', (function () {

      if (window.innerWidth <= 1000) {
        // hide menu after click in mobile devices
        const href = window.$(this).attr("href");
        const dataToggle = window.$(this).attr("data-toggle");
        if (href.indexOf("/") === 0 && dataToggle === undefined) {
          window.$('html').removeClass('nav_open');
          toggle.removeClass('toggled');
          self.nav_open = 0;
        }
      }

      if (window.$(this).parent().find('.collapse').hasClass("show")) {
        window.$(this).parent().removeClass('submenu');
      } else {
        window.$(".nav-item.submenu").each(function () {
          window.$(this).find("a").first().attr("aria-expanded", "false");
          window.$(this).find(".collapse.show").removeClass("show");
          window.$(this).removeClass("submenu");
        });
        window.$(this).parent().addClass('submenu');
      }
    }));
  }

  render() {
    if (this.state.loading) {
      return (
        <div>
          Loading
        </div>
      )
    }
    else {
      return (
        <Router basename="/app">
          {!this.state.loggedIn &&
            <Login onLoggedIn={this.onLoggedIn}></Login>
          }
          {this.state.loggedIn &&
            <React.Fragment>
              <div className="main-header">
                {/* Logo Header */}
                <div className="logo-header">
                  <a href="/app" className="logo" style={{ color: '#fff', paddingTop: '4px', fontSize: '24px' }}>
                    <i style={{ marginRight: '8px' }} className="fas fa-bolt" /> PowerD
                  </a>
                  <button className="navbar-toggler sidenav-toggler ml-auto" type="button" data-toggle="collapse" data-target="collapse" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon">
                      <i className="icon-menu" />
                    </span>
                  </button>
                  <button className="topbar-toggler more"><i className="icon-options-vertical" /></button>
                  <div className="nav-toggle">
                    <button className="btn btn-toggle toggle-sidebar">
                      <i className="icon-menu" />
                    </button>
                  </div>
                </div>
                {/* End Logo Header */}
                {/* Navbar Header */}
                <HeaderNav key={this.state.timestamp}></HeaderNav>
                {/* End Navbar */}
              </div>
              {/* Sidebar */}
              <div className="sidebar sidebar-style-2">
                <div className="sidebar-wrapper scrollbar scrollbar-inner">
                  <div className="sidebar-content">
                    <NavMenu key={this.state.timestamp}></NavMenu>
                  </div>
                </div>
              </div>
              {/* End Sidebar */}
              <div className="main-panel">
                <div className="content">
                  <ErrorBoundary>
                    <AppRoutes></AppRoutes>
                  </ErrorBoundary>
                </div>
                <footer className="footer">
                  <div className="container-fluid">
                    <div className="copyright ml-auto">
                      2019, Nutraceutics Corp.
                    </div>
                  </div>
                </footer>
              </div>
              <Modal id="switchUserModal" title="" hideButtons={true}>
                <PinLogin title="Switch User" onLoggedIn={this.onUserSwitch}></PinLogin>
              </Modal>
              {this.state.isLocked &&
                <div id="lockScreen" style={{ position: "absolute", width: "100%", height: "100%", zIndex: 9999, backdropFilter: "blur(10px)" }}>
                  <div style={{ width: "600px", position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -80%)" }}>
                    {this.state.lockReason === "switch-user" &&
                      <React.Fragment>
                        <h1 style={{ textAlign: "center" }}><i className="fas fa-people-arrows"></i></h1>
                        <PinLogin
                          title={"Switch User"}
                          onBack={() => SharedState.setValue("lockedOut", false)}
                          backButtonText="Cancel"
                          loginButtonText="Switch"
                          onLoggedIn={this.onLoggedIn}
                        >
                        </PinLogin>
                      </React.Fragment>
                    }
                    {this.state.lockReason === "locked" &&
                      <React.Fragment>
                        <h1 style={{ textAlign: "center" }}><i className="fas fa-lock"></i></h1>
                        <PinLogin
                          title={"Enter your PIN to Unlock"}
                          username={this.state.username}
                          loginButtonText="Unlock"
                          onLoggedIn={this.onLoggedIn}
                        >
                        </PinLogin>
                      </React.Fragment>
                    }
                  </div>
                </div>
              }
            </React.Fragment>
          }
        </Router>
      );
    }
  }
}

export default App;

