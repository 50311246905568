import React, { Component } from 'react';
import { HttpRequestService } from '../../services/HttpRequestService';
import moment from 'moment'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { UtilsService } from '../../services/UtilsService';
import StateMultipleSelect from '../../common/StateMultipleSelect';
import ProductTree from '../../common/ProductTree';
import AccountTypeMultipleSelect from '../../common/AccountTypeMultipleSelect';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

class DashboardReport extends Component {

    constructor(props) {
        super(props);
        this.utils = new UtilsService();
        const now = new Date();
        var initialState = {
            productIds: [],
            dateRange: "year-to-date",
            beginDate: moment(new Date(now.getFullYear(), 0, 1)).toDate(),
            endDate: moment(now).toDate(),
            accountTypeIds: [],
            stateIds: [],
            grouping: "Monthly",
            compare: "",
            report: null,
            showInactive: false,
        }
        this.paramsKey = "params.dashboard-report";
        this.state = this.utils.prepareSessionState(initialState, this.paramsKey);

        this.http = new HttpRequestService();
        this.handleBeginDateChange = this.handleBeginDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.handleStatesChange = this.handleStatesChange.bind(this);
        this.handleAccountTypesChange = this.handleAccountTypesChange.bind(this);
        this.handleProductIdsChange = this.handleProductIdsChange.bind(this);
        this.handleGroupingChange = this.handleGroupingChange.bind(this);
        this.handleCompareChange = this.handleCompareChange.bind(this);
        this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
        this.getReport = this.getReport.bind(this);
        this.sortReport = this.sortReport.bind(this);
    }

    componentDidMount() {
        setTimeout(() => {
            try {
                const tree = window.$("#productsTree").jstree();
                tree.select_node("company_2");
                this.getReport();
            }
            catch { }
        }, 1000);
    }

    handleBeginDateChange(e) {
        this.setState({
            beginDate: e,
            dateRange: "custom"
        });
    }

    handleEndDateChange(e) {
        this.setState({
            endDate: e,
            dateRange: "custom"
        });
    }

    handleStatesChange(states) {
        this.setState({ stateIds: states });
    }

    handleAccountTypesChange(accountTypes) {
        this.setState({ accountTypeIds: accountTypes });
    }

    handleGroupingChange(e) {
        this.setState({ grouping: e.target.value });
    }

    handleCompareChange(e) {
        this.setState({ compare: e.target.value });
    }

    handleDateRangeChange(e) {
        const range = e.target.value;
        const now = new Date();
        let startDate = this.state.beginDate;
        let endDate = this.state.endDate;
        if (range === "year-to-date") {
            endDate = moment(now).toDate()
            startDate = moment(new Date(now.getFullYear(), 0, 1)).toDate();
        }
        else if (range === "1-year") {
            endDate = moment(now).toDate()
            startDate = moment(new Date(endDate.getFullYear() - 1, endDate.getMonth(), endDate.getDate())).toDate();
        }
        else if (range === "2-years") {
            endDate = moment(now).toDate()
            startDate = moment(new Date(endDate.getFullYear() - 2, endDate.getMonth(), endDate.getDate())).toDate();
        }
        else if (range === "last-month") {
            startDate = moment(new Date(now.getFullYear(), now.getMonth() - 1, 1)).toDate();
            endDate = moment(startDate).add("months", 1).subtract("days", 1).toDate();
        }
        else if (range === "custom") {

        }
        this.setState({
            dateRange: range,
            beginDate: startDate,
            endDate: endDate
        });
    }

    handleProductIdsChange(val) {
        this.setState({ productIds: val });
    }

    getLabels(groups) {
        const items = groups[0].items;
        if (this.state.grouping === "Daily") {
            return items.map(item => moment(item.day).format("L"));
        }
        else if (this.state.grouping === "Weekly") {
            return items.map(item => item.year + "/" + item.week);
        }
        else if (this.state.grouping === "Monthly") {
            return items.map(item => moment(item.year + "/" + item.month + "/01").format("MMM YYYY"));
        }
        else if (this.state.grouping === "Quarterly") {
            return items.map(item => item.year + "/" + item.quarter);
        }
        else if (this.state.grouping === "Yearly") {
            return items.map(item => item.year);
        }
        else {
            return null;
        }
    }

    // getProducts() {
    //     this.http.get('/api/Orders/GetProducts', { companyId: 2, productTypeId: 1 }).then(data => {
    //         if (this.props.filter) {
    //             data = this.props.filter(data);
    //         }
    //         this.setState({
    //             productIds: data.map(m => m.productID)
    //         }, () => { this.getReport() });
    //     });
    // }

    getReport(e) {
        if (!!e) {
            e.preventDefault();
        }
        var productIds = [];
        var data = window.$("#productsTree").jstree().get_selected();
        for (var i = 0; i < data.length; i++) {
            var parsed = parseInt(data[i]);
            if (!isNaN(parsed)) {
                productIds.push(data[i]);
            }
        }

        this.setState({
            productIds: productIds
        }, () => {
            var params = {
                beginDate: this.state.beginDate != null ? moment(this.state.beginDate).format('L') : "",
                endDate: this.state.endDate != null ? moment(this.state.endDate).format('L') : "",
                accountTypeIds: this.state.accountTypeIds.join(","),
                productIds: this.state.productIds.join(","),
                stateIds: this.state.stateIds.join(","),
                grouping: this.state.grouping,
                compare: this.state.compare
            }
            //sessionStorage.setItem(this.paramsKey, JSON.stringify(params));
            this.http.get('/api/Report/GetDashboardReport', params).then(data => {
                this.setState({ report: data });
                if (this.salesChart) {
                    this.salesChart.destroy();
                }
                this.salesChart = new Chart(
                    document.getElementById('sales-chart-container'),
                    {
                        plugins: [ChartDataLabels],
                        type: 'bar',
                        data: {
                            labels: this.getLabels(data.groups),
                            datasets: data.groups.map((g) => {
                                return {
                                    label: g.name,
                                    data: g.items.map(item => item.total)
                                }
                            })
                        },
                        options: {
                            plugins: {
                                datalabels: {
                                    formatter: (value, context) => {
                                        return this.utils.toCurrency(value, "USD").replace("$", "");
                                    },
                                    font: {
                                        weight: "normal",
                                        size: 12,
                                    },
                                    anchor: "end",
                                    align: "top",
                                    offset: 0,
                                    rotation: data.groups[0].items.length > 16 ? 270 : 0
                                }
                            }
                        }
                    }
                );

                if (this.quantitiesChart) {
                    this.quantitiesChart.destroy();
                }
                this.quantitiesChart = new Chart(
                    document.getElementById('quantities-chart-container'),
                    {
                        plugins: [ChartDataLabels],
                        type: 'bar',
                        data: {
                            labels: this.getLabels(data.groups),
                            datasets: data.groups.map((g) => {
                                return {
                                    label: g.name,
                                    data: g.items.map(item => item.quantity)
                                }
                            })
                        },
                        options: {
                            plugins: {
                                datalabels: {
                                    formatter: (value, context) => {
                                        return this.utils.toCurrency(value, "USD").replace("$", "");
                                    },
                                    font: {
                                        weight: "normal",
                                        size: 12,
                                    },
                                    anchor: "end",
                                    align: "top",
                                    offset: 0,
                                    rotation: data.groups[0].items.length > 16 ? 270 : 0
                                }
                            }
                        }
                    }
                );
            });
        });
    }

    sortReport(field) {
        if (this.sortBy !== field) {
            this.sortDirection = "ASC";
        }
        else {
            if (this.sortDirection === "ASC") {
                this.sortDirection = "DESC";
            }
            else {
                this.sortDirection = "ASC";
            }
        }
        this.sortBy = field;
        this.getReport();
    }

    saveChart(chartContainer) {
        let chart = null;
        let type = "";
        if (chartContainer === "sales-chart-container") {
            chart = this.salesChart;
            type = "sales";
        }
        else {
            chart = this.quantitiesChart;
            type = "units";
        }
        if (chart) {
            const canvas = document.getElementById(chartContainer);
            this.fillCanvasBackgroundWithColor(canvas, '#F3F6FF');
            var a = document.createElement("a");
            a.href = chart.toBase64Image("image/jpeg", 1);
            a.download = this.state.grouping.toLowerCase() + "_" + type + ".jpeg";
            a.click();
        }
    }

    fillCanvasBackgroundWithColor(canvas, color) {
        const context = canvas.getContext('2d');
        context.save();
        context.globalCompositeOperation = 'destination-over';
        context.fillStyle = color;
        context.fillRect(0, 0, canvas.width, canvas.height);
        context.restore();
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Dashboard Report</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={e => this.getReport(e)}>
                                    <div className="form-row">
                                        <div className="col-md-8">
                                            <div className="form-row">
                                                <div className="form-group col-md-2 ">
                                                    <label htmlFor="beginDate">Date Range</label>
                                                    <select className="form-control" value={this.state.dateRange} onChange={this.handleDateRangeChange}>
                                                        <option value="year-to-date">Year to Date</option>
                                                        <option value="1-year">1 Year</option>
                                                        <option value="2-years">2 Years</option>
                                                        <option value="last-month">Last Month</option>
                                                        <option value="custom">Custom</option>
                                                    </select>
                                                </div>
                                                <div className="form-group col-md-2 ">
                                                    <label htmlFor="beginDate">Invoices From</label>
                                                    <DatePicker readOnly={this.state.dateRange !== "custom"} className="form-control" selected={this.state.beginDate} onChange={this.handleBeginDateChange} />
                                                </div>
                                                <div className="form-group col-md-2">
                                                    <label htmlFor="endDate">Invoices To</label>
                                                    <DatePicker readOnly={this.state.dateRange !== "custom"} className="form-control" selected={this.state.endDate} onChange={this.handleEndDateChange} />
                                                </div>
                                                <div className="form-group col-md-2">
                                                    <label>Grouping</label>
                                                    <select className="form-control" value={this.state.grouping} onChange={this.handleGroupingChange}>
                                                        <option>Daily</option>
                                                        <option>Weekly</option>
                                                        <option>Monthly</option>
                                                        <option>Quarterly</option>
                                                        <option>Yearly</option>
                                                    </select>
                                                </div>
                                                <div className="form-group col-md-2">
                                                    <label>Compare</label>
                                                    <select className="form-control" value={this.state.compare} onChange={this.handleCompareChange}>
                                                        <option>--None--</option>
                                                        <option>Products</option>
                                                        <option>States</option>
                                                        <option>Sources</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-4">
                                                    <label>Sources</label>
                                                    <AccountTypeMultipleSelect value={this.state.accountTypeIds} additionalOptions={[{ key: 22, value: "Target" }]} onChange={this.handleAccountTypesChange}></AccountTypeMultipleSelect>
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label>States</label>
                                                    <StateMultipleSelect filter={this.filterStates} countryID={211} value={this.state.stateIds} onChange={this.handleStatesChange}></StateMultipleSelect>
                                                </div>
                                                {/* <div className="form-group col-md-12">
                                                    <label>Products</label>
                                                    <ProductMultipleDropdown value={this.state.productIds} companyId={2} inventoryTypeId={1} onChange={this.handleProductIdsChange}></ProductMultipleDropdown>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group" style={{ maxHeight: "500px", overflow: "auto" }}>
                                                <label>Products</label>
                                                <ProductTree flat={true} resaleOnly={true} value={this.state.productIds} onChange={this.handleProductIdsChange}></ProductTree>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row justify-content-end">
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        {this.state.report != null &&
                            <React.Fragment>
                                <div className="card col-md-12">
                                    <div className="card-header">
                                        <div className="card-head-row">
                                            <div className="card-title">{this.state.grouping} Sales</div>
                                            <div className="card-tools">
                                                <button type="button" className="btn btn-info btn-border btn-round btn-sm mr-2" onClick={() => { this.saveChart("sales-chart-container") }}>
                                                    <span className="btn-label">
                                                        <i className="fas fa-save"></i>
                                                    </span>
                                                    Save As Image
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body pb-0">
                                        <canvas id="sales-chart-container"></canvas>
                                    </div>
                                </div>
                                <div className="card col-md-12">
                                    <div className="card-header">
                                        <div className="card-head-row">
                                            <div className="card-title">{this.state.grouping} Units</div>
                                            <div className="card-tools">
                                                <button type="button" className="btn btn-info btn-border btn-round btn-sm mr-2" onClick={() => { this.saveChart("quantities-chart-container") }}>
                                                    <span className="btn-label">
                                                        <i className="fas fa-save"></i>
                                                    </span>
                                                    Save As Image
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body pb-0">
                                        <canvas id="quantities-chart-container"></canvas>
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                    </div>
                </div>
            </div >
        );
    }
}

export default DashboardReport;
