import React, { Component } from 'react';
import { HttpRequestService } from '../services/HttpRequestService';
import Checkbox from './Checkbox';

class ProductTree extends Component {

    constructor(props) {
        super(props);
        var selectedProducts = this.props.value ? this.props.value : [];
        if (!Array.isArray(selectedProducts)) {
            selectedProducts = selectedProducts.split(',');
        }
        this.state = {
            csoProducts: [],
            selectedProducts: selectedProducts,
            showInactive: false
        };
        this.http = new HttpRequestService();
        this.handleShowInactiveChange = this.handleShowInactiveChange.bind(this);
        this.getProductTree = this.getProductTree.bind(this);
    }

    componentDidMount() {
        if (this.props.cso) {
            this.getCsoProductTree();
        }
        else {
            this.getProductTree();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.companyId !== prevProps.companyId || this.props.productTypeId !== prevProps.productTypeId) {
            this.getProducts();
        }
    }

    getCsoProductTree() {
        this.http.get('/api/Inventory/GetCsoProducts').then(data => {
            this.filterProducts = (products) => {
                return products.filter(p => data.indexOf(p.id) >= 0);
            }
            this.getProductTree();
        });
    }

    getProductTree() {
        var params = {
            activeOnly: !this.state.showInactive,
            resaleOnly: this.props.resaleOnly ? this.props.resaleOnly : false
        };
        this.http.get('/api/Inventory/GetProductTree', params).then(data => {
            var tree = data;
            var treeData = [];
            for (var i = 0; i < tree.companies.length; i++) {
                var company = tree.companies[i];
                var companyNode = {
                    id: "company_" + company.id,
                    text: company.companyName,
                    children: []
                };

                // Display Amazon and Resale categories combined
                const amazonProductType = company.productTypes.find(p => p.type === "Amazon");
                const resaleProductType = company.productTypes.find(p => p.type === "Resale");
                const amazonAndResaleCombined = amazonProductType && resaleProductType;
                if (amazonAndResaleCombined) {
                    var containerNode = {
                        id: companyNode.id + "_productType_" + "amazon_and_resale_container",
                        text: "Resale",
                        children: []
                    };

                    var amazonNode = {
                        id: companyNode.id + "_productType_" + amazonProductType.id,
                        text: amazonProductType.type,
                        children: []
                    }

                    // filter products
                    if (this.filterProducts) {
                        amazonProductType.products = this.filterProducts(amazonProductType.products);
                    }

                    for (var k = 0; k < amazonProductType.products.length; k++) {
                        var product = amazonProductType.products[k];
                        var productNode = {
                            id: product.id,
                            text: product.name,
                            icon: "jstree-file"
                        }

                        amazonNode.children.push(productNode);
                    }

                    if (amazonNode.children.length > 0) {
                        containerNode.children.push(amazonNode);
                    }

                    var nonAmazonNode = {
                        id: companyNode.id + "_productType_" + resaleProductType.id,
                        text: "Non-Amazon",
                        children: []
                    }

                    // filter products
                    if (this.filterProducts) {
                        resaleProductType.products = this.filterProducts(resaleProductType.products);
                    }

                    for (var k = 0; k < resaleProductType.products.length; k++) {
                        var product = resaleProductType.products[k];
                        var productNode = {
                            id: product.id,
                            text: product.name,
                            icon: "jstree-file"
                        }

                        nonAmazonNode.children.push(productNode);
                    }
                    if (nonAmazonNode.children.length > 0) {
                        containerNode.children.push(nonAmazonNode);
                    }
                    if (containerNode.children.length > 0) {
                        companyNode.children.push(containerNode);
                    }
                }

                for (var j = 0; j < company.productTypes.length; j++) {
                    var productType = company.productTypes[j];
                    if (amazonAndResaleCombined && (productType.type === "Resale" || productType.type === "Amazon")) {
                        continue;
                    }

                    var productTypeNode = {
                        id: companyNode.id + "_productType_" + productType.id,
                        text: productType.type,
                        children: []
                    }

                    // filter products
                    if (this.filterProducts) {
                        productType.products = this.filterProducts(productType.products);
                    }
                    for (var k = 0; k < productType.products.length; k++) {
                        var product = productType.products[k];
                        var productNode = {
                            id: product.id,
                            text: product.name,
                            icon: "jstree-file"
                        }
                        productTypeNode.children.push(productNode);
                    }

                    if (productTypeNode.children.length > 0) {
                        companyNode.children.push(productTypeNode);
                    }
                }

                if (companyNode.children.length > 0) {
                    if (this.props.flat) {
                        companyNode.children = this.flatify(companyNode);
                        treeData.push(companyNode);
                    }
                    else {
                        treeData.push(companyNode);
                    }
                }
            }
            

            window.$("#productsTree").jstree("destroy");

            window.$('#productsTree')
                .on('changed.jstree', (e, data) => {
                    this.setState({
                        selectedProducts: data.selected
                    }, this.notifyChange)
                })
                .jstree({
                    "core": {
                        "data": treeData,
                    },
                    "plugins": ["checkbox"],

                });

            setTimeout(() => {
                var selectedProducts = this.state.selectedProducts;
                for (var i = 0; i < selectedProducts.length; i++) {
                    window.$("#productsTree").jstree('select_node', selectedProducts[i]);
                }
            }, 500);
        });
    }

    flatify(node){
        let children = [];
        if(node.children){
            for(const child of node.children){
                children = [...children, ...this.flatify(child)];
            }
        }
        else{
            children = [node];
        }
        return children;
    }

    handleShowInactiveChange(checked) {
        this.setState({ showInactive: checked }, this.getProductTree);
    }

    notifyChange() {
        if (this.props.onChange) {
            this.props.onChange(this.state.selectedProducts);
        }
    }

    render() {
        return (
            <>
                <Checkbox label="Show Inactive" checked={this.state.showInactive} onChange={this.handleShowInactiveChange}></Checkbox>
                <div id="productsTree"></div>
            </>
        );
    }
}
export default ProductTree
