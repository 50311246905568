import React, { Component } from 'react';
import { HttpRequestService } from '../services/HttpRequestService';
import Select from 'react-select';

class AccountTypeMultipleSelect extends Component {

    constructor(props) {
        super(props);
        this.state = {
            items: []
        };
        this.getData = this.getData.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.http = new HttpRequestService();
    }

    componentDidMount() {
        this.getData();
    }

    componentDidUpdate(prevProps) {
    }

    getData() {
        const params = {
            onlyActive: this.props.includePassive ? false : true
        }
        this.http.get('/api/Orders/GetAccountTypes', params).then(data => {
            if (this.props.filter) {
                data = this.props.filter(data);
            }
            if(this.props.additionalOptions){
                data = [...data, ...this.props.additionalOptions];
            }
            this.setState({ items: data });
            if (data.length > 0 && this.props.value === 0) {
                this.props.onChange(data[0]);
            }
        });
    }

    handleChange(e) {
        if (e) {
            this.props.onChange(e.map((i) => { return i.value }));
        }
        else {
            this.props.onChange([]);
        }
    }

    render() {
        var options = this.state.items.map((item, index) => {
            const selected = this.props.value.indexOf(parseInt(item.key)) >= 0 || this.props.value.indexOf(item.key) >= 0;
            return { label: item.value, value: item.key, selected: selected };
        });
        var selectedOptions = options.filter(o => o.selected === true);
        return (
            <Select isClearable={true} options={options} isMulti={true} value={selectedOptions} onChange={this.handleChange}></Select>
        );
    }
}
export default AccountTypeMultipleSelect
