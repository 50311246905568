import React, { Component } from 'react';
import { HttpRequestService } from '../../services/HttpRequestService';
import moment from 'moment'
import DatePicker from "react-datepicker";
import ProductSelect from '../../common/ProductSelect';
import Modal from '../../common/Modal';

class AddBatch extends Component {
    constructor(props) {
        super(props);
        this.http = new HttpRequestService();
        this.mode = "add";
        if (this.props.match.params.id) {
            this.mode = "view";
        }
        this.state = {
            batchId: 0,
            description: "",
            date: moment().toDate(),
            product: -1,
            quantity: 0,
            products: []
        };
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleProductChange = this.handleProductChange.bind(this);
        this.handleQuantityChange = this.handleQuantityChange.bind(this);
        this.addProduct = this.addProduct.bind(this);
        this.removeProduct = this.removeProduct.bind(this);
        this.createBatch = this.createBatch.bind(this);
        this.getBatch = this.getBatch.bind(this);
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            this.getBatch();
        }
    }

    handleDescriptionChange(e) {
        this.setState({
            description: e.target.value
        })
    }

    handleDateChange(value) {
        this.setState({
            date: value
        });
    }

    handleProductChange(value) {
        this.setState({
            product: value.productID
        })
    }

    handleQuantityChange(e) {
        this.setState({
            quantity: e.target.value
        })
    }

    getBatch() {
        var params = {
            batchId: this.props.match.params.id
        }
        this.http.get('/api/Inventory/GetBatch', params).then(data => {
            this.setState({
                batchId: data.batchId,
                description: data.description,
                date: moment(data.date).toDate(),
                products: data.products
            })
        });
    }

    addProduct() {
        var params = {
            batchId: this.state.batchId,
            productId: this.state.product,
            quantity: this.state.quantity
        }
        this.http.get('/api/Inventory/AddBatchProduct', params).then(data => {
            window.$("#addProductModal").modal("hide");
            this.setState({
                products: data.products
            })
        });
    }

    removeProduct(product) {
        var params = {
            batchId: this.state.batchId,
            inventoryAdjustmentId: product.inventoryAdjustmentID
        }
        this.http.get('/api/Inventory/RemoveBatchProduct', params).then(data => {
            this.setState({
                products: data.products
            })
        });
    }

    createBatch() {
        var params = {
            description: this.state.description,
            date: this.state.date
        }
        this.http.get('/api/Inventory/CreateBatch', { model: JSON.stringify(params) }).then(data => {
            this.setState({
                batchId: data.batchId
            })
        });
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    {this.mode === "add" &&
                        <h4 className="page-title">Add Batch</h4>
                    }
                    {this.mode === "view" &&
                        <h4 className="page-title">View Batch</h4>
                    }
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card full-height">
                            <div className="card-header">
                                <div className="card-title">Batch Information</div>
                            </div>
                            <div className="card-body">
                                <div className="form-row">
                                    <div className="form-group col-md-8">
                                        <label>Batch Name</label>
                                        <input type="text" className="form-control" value={this.state.description} onChange={this.handleDescriptionChange}></input>
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label>Batch Date</label>
                                        <DatePicker className="form-control" selected={this.state.date} onChange={this.handleDateChange} />
                                    </div>
                                </div>
                            </div>
                            {this.state.batchId === 0 && this.mode === "add" &&
                                <div className="card-footer">
                                    <button className="btn btn-primary btn-default pull-right mr-2" onClick={this.createBatch}>Create Batch</button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {this.state.batchId > 0 &&
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card full-height">
                                <div className="card-header">
                                    <div className="card-head-row">
                                        <div className="card-title">Products</div>
                                        {this.mode === "add" &&
                                            <div className="card-tools">
                                                <button className="btn btn-info btn-border btn-round btn-sm mr-2" data-toggle="modal" data-target="#addProductModal">
                                                    <span className="btn-label">
                                                        <i className="fas fa-plus"></i>
                                                    </span>
                                                    Add
                                                </button>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="form-row">
                                        <table className="table table-striped table-bordered mt-3">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Product Name</th>
                                                    <th scope="col">Quantity</th>
                                                    <th scope="col"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.product && this.state.products.map(item =>
                                                    <tr key={item.productID}>
                                                        <td>{item.productName}</td>
                                                        <td>{item.quantity}</td>
                                                        <td><button className="btn btn-sm btn-danger" onClick={(e) => this.removeProduct(item, e)}><i className="fas fa-trash"></i></button></td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                    <Modal id="addProductModal" title="Add Product" onOk={this.addProduct}>
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <label>Product</label>
                                                <ProductSelect allProducts={true} value={this.state.product} onChange={this.handleProductChange}></ProductSelect>
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label>Quantity</label>
                                                <input type="text" className="form-control" value={this.state.quantity} onChange={this.handleQuantityChange}></input>
                                            </div>
                                        </div>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default AddBatch;
