import React, { Component } from 'react';
import { HttpRequestService } from '../../services/HttpRequestService';
import ASINInformation from './ASINInformation';

class AddASIN extends Component {
    constructor(props) {
        super(props);
        this.http = new HttpRequestService();
        this.state = {
            asinInformation: {}
        };
        this.handleASINInfoChange = this.handleASINInfoChange.bind(this);
        this.createASIN = this.createASIN.bind(this);
    }

    handleASINInfoChange(value) {
        this.setState({
            asinInformation: value
        })
    }

    createASIN() {
        this.http.get('/api/Inventory/CreateASIN', { model: JSON.stringify(this.state.asinInformation) }).then(data => {
            this.props.history.push('/inventory/asin/' + data);
        });
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Add ASIN</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card full-height">
                            <div className="card-header">
                                <div className="card-title">ASIN Information</div>
                            </div>
                            <div className="card-body">
                                <ASINInformation onChange={this.handleASINInfoChange}></ASINInformation>
                            </div>
                            <div className="card-footer">
                                <button className="btn btn-primary btn-default pull-right mr-2" onClick={this.createASIN}>Create ASIN</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddASIN;
