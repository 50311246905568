import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { HttpRequestService } from './services/HttpRequestService';

class NavMenu extends Component {

  constructor(props) {
    super(props);
    this.state = {
      user: null
    }
    this.http = new HttpRequestService();
    this.getUser = this.getUser.bind(this);
  }

  componentDidMount() {
    this.getUser()
  }

  getUser() {
    this.http.get('/api/PowerD/GetLoggedInUser', null).then(data => {
      this.setState({ user: data });
    });
  }

  allowedUsers = ["gokberk", "efficiondave", "bcherry"];
  menuTree = [
    { name: "Dashboard", path: "/", iconClass: "fas fa-home" },
    { name: "Pages", type: "separator" },
    {
      name: "Customers", path: "/", iconClass: "fas fa-users", roles: ["Customer"], children: [
        { name: "Search", path: "/crm/customer-search" },
        { name: "Advanced Search", path: "/crm/account-list" },
        { name: "Create a List", path: "/crm/active-accounts-list" },
        { name: "Add Customer", path: "/customers/add" },
        { name: "Customer Product Search", path: "/customers/product-search" },
        { name: "Customer Comments", path: "/customers/comments" },
      ]
    },
    {
      name: "Contacts", path: "/", iconClass: "fas fa-address-card", roles: ["Customer"], children: [
        { name: "Contact Search", path: "/crm/contact-list" },
        { name: "Add Contact", path: "/crm/add-contact" },
      ]
    },
    {
      name: "Inventory", path: "/", iconClass: "fas fa-boxes", roles: ["Inventory"], children: [
        { name: "Add Product", path: "/inventory/add-product" },
        { name: "Search", path: "/inventory" },
        { name: "Product Order", path: "/inventory/product-order" },
        { name: "Add Batch", path: "/inventory/add-batch" },
        { name: "Batch List", path: "/inventory/batch-list" },
        { name: "ASIN Matchings", path: "/inventory/asin-list" },
        { name: "Add ASIN Matching", path: "/inventory/add-asin" }
      ]
    },
    {
      name: "Orders", path: "/", iconClass: "fas fa-list", roles: ["Order", "Warehouse"], children: [
        { name: "Add Order", path: "/orders/start-new" },
        { name: "Search", path: "/orders" },
        { name: "On Hold", path: "/orders-on-hold" },
        { name: "Auto-Shipped Orders", path: "/orders/auto-ship" },
      ]
    },
    {
      name: "Amazon Orders", path: "/", iconClass: "fab fa-amazon", roles: ["Order"], children: [
        { name: "Search", path: "/amazon-orders" },
        { name: "Report", path: "/amazon-orders/report" },
        { name: "Reconciliation", path: "/amazon-orders/reconcile" },
      ]
    },
    {
      name: "Walmart Orders", path: "/", iconClass: "fa fa-asterisk", roles: ["Order"], children: [
        { name: "Search", path: "/walmart-orders" },
      ]
    },
    {
      name: "Fulfillment", path: "/", iconClass: "fas fa-tasks", roles: ["Fulfillment", "Warehouse"], children: [
        // { name: "Samples", path: "/samples-fulfillment" },
        { name: "Search", path: "/fulfillment-paperless" },
        { name: "Web Orders", path: "/web-fulfillment" },
        { name: "Recently Fulfilled Orders", path: "/recently-fulfilled" },
        { name: "End Of Day Report", path: "/reports/fedex-end-of-day-report" },
        { name: "Shipment Log", path: "/admin/shipment-log" },
      ]
    },
    {
      name: "Invoices", path: "/", iconClass: "fas fa-file-invoice-dollar", roles: ["Invoice", "Warehouse"], children: [
        { name: "Search", path: "/invoices" },
        { name: "BW Export", path: "/invoices/bw-export" }
      ]
    },
    {
      name: "Reports", path: "/", iconClass: "fas fa-chart-line", roles: ["Reports"], children: [
        { name: "Invoice Report", path: "/reports/invoice-report" },
        { name: "Customer Category Report", path: "/reports/customer-category-report" },
        { name: "Customer Comments Report", path: "/reports/customer-comments-report" },
        { name: "Product Sales Report", path: "/reports/product-sales-report" },
        { name: "Sales by Reps Report", path: "/reports/sales-by-reps-report" },
        { name: "CSO Product Sales Report", path: "/reports/cso-product-sales-report" },
        { name: "CSO Benchmark Report", path: "/reports/cso-benchmark-report" },
        { name: "Sales Taxes Report", path: "/reports/sales-taxes-report" },
        { name: "Promotions Report", path: "/reports/promotions-report" },
        { name: "Inventory Report", path: "/reports/inventory-report" },
        { name: "Lot Report", path: "/reports/lot-report" },
        { name: "Monthly Inventory Reports", path: "/reports/monthly-inventory-reports" },
        { name: "Sales by Hold Reason Report", path: "/reports/sales-by-hold-reason-report" },
        { name: "Dashboard Report", path: "/reports/dashboard-report" },
      ]
    },
    {
      name: "Users", path: "/", iconClass: "fas fa-user", roles: ["Users"], children: [
        { name: "Add User", path: "/users/add" },
        { name: "User List", path: "/users" }
      ]
    },
    {
      name: "Coupons", path: "/admin/coupons", roles: ["Coupons"], iconClass: "fas fa-tags"
    },
    {
      name: "Admin", path: "/", iconClass: "fas fa-chart-line", roles: ["Admin"], children: [
        { name: "Saved Reports", path: "/admin/saved-reports" },
        { name: "User Defined Fields", path: "/crm/user-defined-fields" },
        { name: "Customer Hold", path: "/admin/customer-hold" },
        { name: "Add Category", path: "/admin/add-category" },
        { name: "Category List", path: "/admin/categories" },
        { name: "Categories & Specialties", path: "/admin/category-specialty-grid" },
        { name: "Change Order Status", path: "/admin/change-order-status" },
        { name: "Update Shipping Costs", path: "/admin/shipping-costs" },
        { name: "Settings", path: "/admin/settings" },
        { name: "Company Email Sending Accounts", path: "/admin/company-email-accounts" },
        { name: "SMTP Accounts", path: "/admin/smtp-accounts" },
        { name: "EDI Log", path: "/admin/edi-log" },
        { name: "Error Log", path: "/admin/error-log" },
        { name: "Authorization Log", path: "/admin/authorization-log" },
      ]
    },
  ];

  getMenu() {
    return (
      <React.Fragment>
        {this.state.user &&
          <ul className="nav nav-primary">
            {this.menuTree.map(item => this.getMenuItem(item))}
          </ul>
        }
      </React.Fragment>
    );
  }

  getMenuItem(item) {
    var canView = (!item.roles) || this.state.user.roles.find(ur => ur === "Admin") || item.roles.find(r => this.state.user.roles.find(ur => r === ur));
    if (!canView) {
      return null;
    }
    if (item.type === "separator") {
      return (
        <li key={item.name} className="nav-section">
          <span className="sidebar-mini-icon">
            <i className="fa fa-ellipsis-h" />
          </span>
          <h4 className="text-section">{item.name}</h4>
        </li>
      );
    }
    else if (item.children) {
      return (
        <React.Fragment key={item.name}>
          {item.name &&
            <li className="nav-item">
              <a data-toggle="collapse" href={"#" + item.name}>
                <i className={item.iconClass} />
                <p>{item.name}</p>
                <span className="caret" />
              </a>
              {this.getSubMenuItems(item)}
            </li>
          }
        </React.Fragment>
      );
    }
    else {
      return (
        <React.Fragment key={item.name}>
          {item.name &&
            <li className="nav-item">
              <Link to={item.path}>
                <i className={item.iconClass}></i>
                <p>{item.name}</p>
              </Link>
            </li>
          }
        </React.Fragment>
      );
    }
  }

  getSubMenuItems(item) {
    return (
      <div className="collapse" id={item.name}>
        <ul className="nav nav-collapse">
          {item.children.map((child, index) =>
            <React.Fragment key={index}>
              {child.name &&
                <li>
                  <Link to={child.path}>
                    <span className="sub-item">{child.name}</span>
                  </Link>
                </li>
              }
            </React.Fragment>
          )}
        </ul>
      </div>
    );
  }

  render() {
    return this.getMenu();
  }
}
export default NavMenu
