import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { HttpRequestService } from '../../services/HttpRequestService';
import moment from 'moment'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { UtilsService } from '../../services/UtilsService';
import Report from '../../common/Report';

class CsoBenchmarkReport extends Component {

    constructor(props) {
        super(props);
        this.utils = new UtilsService();
        var initialState = {
            beginDate: moment(new Date()).toDate(),
            endDate: moment(new Date()).toDate(),
            report: null,
            showInactive: false,
        }
        this.sortBy = "";
        this.sortDirection = "";
        this.paramsKey = "params.cso-benchmark-report";
        this.state = this.utils.prepareSessionState(initialState, this.paramsKey);

        this.http = new HttpRequestService();
        this.handleBeginDateChange = this.handleBeginDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.getReport = this.getReport.bind(this);
        this.sortReport = this.sortReport.bind(this);
    }

    componentDidMount() {
    }

    handleBeginDateChange(e) {
        this.setState({ beginDate: e });
    }

    handleEndDateChange(e) {
        this.setState({ endDate: e });
    }

    getPrintPDFUrl(type, order) {
        return this.http.HOST + "/Print/Order?type=" + type + "&OrderID=" + order.orderID + "&OrderNumber=" + order.orderNumber;
    }

    getReport(e) {
        if (!!e) {
            e.preventDefault();
        }
        var params = {
            beginDate: this.state.beginDate != null ? moment(this.state.beginDate).format('L') : "",
            endDate: this.state.endDate != null ? moment(this.state.endDate).format('L') : "",
        }
        sessionStorage.setItem(this.paramsKey, JSON.stringify(params));
        this.http.get('/api/Report/GetCsoBenchmarkReport', params).then(data => {
            this.setState({ report: data });
        });
    }

    sortReport(field) {
        if (this.sortBy !== field) {
            this.sortDirection = "ASC";
        }
        else {
            if (this.sortDirection === "ASC") {
                this.sortDirection = "DESC";
            }
            else {
                this.sortDirection = "ASC";
            }
        }
        this.sortBy = field;
        this.getReport();
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">CSO Benchmark Report</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={e => this.getReport(e)}>
                                    <div className="form-row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="beginDate">Invoices From</label>
                                                <DatePicker className="form-control" selected={this.state.beginDate} onChange={this.handleBeginDateChange} />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="endDate">Invoices To</label>
                                                <DatePicker className="form-control" selected={this.state.endDate} onChange={this.handleEndDateChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row justify-content-end">
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        {this.state.report != null &&
                            <Report name="CsoBenchmarkReport" hasData={this.state.report.items && this.state.report.items.length > 0} title="CSO Benchmark Report" description={this.state.report.description}>
                                <div className="form-row">
                                    <table className="table table-striped mt-1 report-table">
                                        <thead>
                                            <tr>
                                                <th>Month Date</th>
                                                <th>State</th>
                                                <th>Source</th>
                                                <th>Product Name</th>
                                                <th>Quantity</th>
                                                <th>Sales</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <React.Fragment>
                                                {this.state.report.items.map((monthGroup) =>
                                                    <React.Fragment key={monthGroup.name}>
                                                        {monthGroup.stateItems && monthGroup.stateItems.map((stateGroup) =>
                                                            <React.Fragment>
                                                                {stateGroup.items.map((salesmanGroup) =>
                                                                    <React.Fragment>
                                                                        {salesmanGroup.items && salesmanGroup.items.map((productGroup, index) =>
                                                                            <tr key={index}>
                                                                                <td>{monthGroup.name}</td>
                                                                                <td>{stateGroup.name}</td>
                                                                                <td>{salesmanGroup.name}</td>
                                                                                <td>{productGroup.productName}</td>
                                                                                <td className="currency">{productGroup.quantityShipped}</td>
                                                                                <td className="currency">{this.utils.toCurrency(productGroup.calculatedTotal, productGroup.currencyCode)}</td>
                                                                            </tr>
                                                                        )}
                                                                    </React.Fragment>
                                                                )}
                                                            </React.Fragment>
                                                        )}
                                                    </React.Fragment>
                                                )}
                                            </React.Fragment>
                                        </tbody>
                                    </table>
                                </div>
                            </Report>
                        }
                    </div>
                </div>
            </div >
        );
    }
}

export default CsoBenchmarkReport;
