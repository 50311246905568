import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import Pagination from '../../common/Pagination'
import { HttpRequestService } from '../../services/HttpRequestService';
import { UtilsService } from '../../services/UtilsService';
import Checkbox from '../../common/Checkbox';

class CustomerSearch extends Component {

    constructor(props) {
        super(props);
        this.http = new HttpRequestService();
        this.utils = new UtilsService();
        var initialState = {
            criteria: "phoneNumber",
            keyword: "",
            activeOnly: false,
            companyName: "",
            customer: "",
            customerNumber: "",
            customers: [],
            pageCount: 0,
            pageIndex: 0
        }

        this.paramsKey = "params.customer-search";
        
        if (this.props.location.state && this.props.location.state.loadParams) {
            this.state = this.utils.prepareSessionState(initialState, this.paramsKey);
        }
        else {
            this.state = this.utils.prepareSessionState(initialState, "");
        }

        this.handleCriteriaChange = this.handleCriteriaChange.bind(this);
        this.handleKeywordChange = this.handleKeywordChange.bind(this);
        this.handleCustomerChange = this.handleCustomerChange.bind(this);
        this.handleCustomerNumberChange = this.handleCustomerNumberChange.bind(this);
        this.handleCompanyNameChange = this.handleCompanyNameChange.bind(this);
        this.handleActiveOnlyChange = this.handleActiveOnlyChange.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.pageSize = 100;
        this.getCustomers = this.getCustomers.bind(this);
    }

    componentDidMount() {
        if (this.props.location.state && this.props.location.state.loadParams) {
            this.getCustomers(0);
        }
    }

    handleCriteriaChange(e) {
        this.setState({ criteria: e.target.value });
    }

    handleKeywordChange(e) {
        this.setState({ keyword: e.target.value });
    }

    handleCompanyNameChange(e) {
        this.setState({ companyName: e.target.value });
    }

    handleCustomerNumberChange(e) {
        this.setState({ customerNumber: e.target.value });
    }

    handleCustomerChange(e) {
        this.setState({ customer: e.target.value });
    }


    handleActiveOnlyChange(checked) {
        this.setState({ activeOnly: checked });
    }

    handlePageChange(page) {
        this.getCustomers(page);
    }

    getCustomers(page, e) {
        if (!!e) {
            e.preventDefault();
        }
        var searchFields = {}

        if (this.state.customer) {
            searchFields.fullName = this.state.customer;
        }

        if (this.state.customerNumber) {
            searchFields.customerNumber = this.state.customerNumber;
        }
        if (this.state.companyName) {
            searchFields.companyName = this.state.companyName;
        }
        if (this.state.keyword) {
            searchFields[this.state.criteria] = this.state.keyword;
        }

        this.setState({ pageIndex: page });
        var params = {
            criteria: JSON.stringify(searchFields),
            activeOnly: this.state.activeOnly,
            pageSize: this.pageSize,
            pageIndex: page
        }
        var sessionState = {
            companyName: this.state.companyName,
            customer: this.state.customer,
            customerNumber: this.state.customerNumber,
            criteria: this.state.criteria,
            keyword: this.state.keyword,
            pageIndex: page
        }
        sessionStorage.setItem(this.paramsKey, JSON.stringify(sessionState));

        this.http.get('/api/Customers/SearchCustomers', params).then(data => {
            this.setState({ customers: data.data, pageCount: parseInt(Math.ceil(data.count / this.pageSize)) });
        });
    }

    changeStatus(customer) {
        var params = {
            customerId: customer.customerID
        }
        this.http.get('/api/Customers/ChangeCustomerStatus', params).then(data => {
            customer.onHold = !customer.onHold;
            this.forceUpdate();
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="card">
                    <div className="card-body">
                        <form onSubmit={(e) => this.getCustomers(0, e)}>
                            <div className="form-row">
                                <div className="form-group col-md-3">
                                    <label>Account Name</label>
                                    <input type="text" className="form-control" value={this.state.companyName} onChange={this.handleCompanyNameChange}></input>
                                </div>
                                <div className="form-group col-md-3">
                                    <label>Account Number</label>
                                    <input type="text" className="form-control" value={this.state.customerNumber} onChange={this.handleCustomerNumberChange}></input>
                                </div>
                                <div className="form-group col-md-3">
                                    <label>Contact</label>
                                    <input type="text" className="form-control" value={this.state.customer} onChange={this.handleCustomerChange}></input>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-3">
                                    <label>Criteria</label>
                                    <select className="form-control" value={this.state.criteria} onChange={this.handleCriteriaChange}>
                                        <option value="phoneNumber">Phone Number</option>
                                        <option value="zipCode">Zip Code</option>
                                        <option value="address">Street Address</option>
                                        <option value="email">Email</option>
                                        <option value="orderNumber">Order Number</option>
                                        <option value="poNumber">PO #</option>
                                    </select>
                                </div>
                                <div className="form-group col-md-3">
                                    <label>Keyword</label>
                                    <input type="text" className="form-control" value={this.state.keyword} onChange={this.handleKeywordChange}></input>
                                </div>
                                <div className="form-group col-md-2">
                                    <label>&nbsp;</label>
                                    <Checkbox label="Active Only" checked={this.state.activeOnly} onChange={this.handleActiveOnlyChange}></Checkbox>
                                </div>
                                <div className="form-group col-md-3">
                                    <button type="submit" className="btn btn-primary mt-4">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="form-row">
                            <table className="table table-striped mt-0">
                                <thead>
                                    <tr>
                                        <th scope="col">Account Number</th>
                                        {(this.state.criteria === "orderNumber" || this.state.criteria === "poNumber")  && this.state.keyword !== "" &&
                                            <th scope="col">Order #</th>
                                        }
                                        <th scope="col">Account Name</th>
                                        <th scope="col">First Name</th>
                                        <th scope="col">Last Name</th>
                                        <th scope="col">Phone Number</th>
                                        <th scope="col">Active</th>
                                        {this.props.placeOrder &&
                                            <th></th>
                                        }
                                        {this.props.hold &&
                                            <React.Fragment>
                                                <th>On Hold</th>
                                                <th></th>
                                            </React.Fragment>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.customers.map((customer) =>
                                        <tr key={customer.customerID}>
                                            <td><Link to={{ pathname: "/crm/account/" + customer.accountID, state: { displayBackButton: true, searchUrl: "/crm/customer-search" } }}>{customer.accountNumber}</Link></td>
                                            {(this.state.criteria === "orderNumber" || this.state.criteria === "poNumber") && this.state.keyword !== "" &&
                                                <td><Link to={"/order/" + customer.orderID}>{customer.orderNumber}</Link></td>
                                            }
                                            <td>{customer.name}</td>
                                            <td>{customer.primaryContactFirstName}</td>
                                            <td>{customer.primaryContactLastName}</td>
                                            <td>{customer.phoneNumbers.length > 0 ? customer.phoneNumbers[0].number : ""}</td>
                                            <td>{customer.isActive.toString()}</td>
                                            {this.props.placeOrder &&
                                                <td><Link className="btn btn-sm btn-info" to={"/orders/add/" + customer.customerID}>Place Order <i className="fas fa-angle-right"></i></Link></td>
                                            }
                                            {this.props.hold &&
                                                <React.Fragment>
                                                    <td>{customer.onHold ? "yes" : "no"}</td>
                                                    <td><button className="btn btn-sm btn-info" onClick={e => this.changeStatus(customer)}>Change Status </button></td>
                                                </React.Fragment>
                                            }
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                {this.state.customers.length > 0 &&
                                    <Pagination pageIndex={this.state.pageIndex} pageCount={this.state.pageCount} onPageChanged={this.handlePageChange}></Pagination>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(CustomerSearch);
