import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { HttpRequestService } from '../../services/HttpRequestService';
import moment from 'moment'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { UtilsService } from '../../services/UtilsService';
import StateSelect from '../../common/StateSelect';
import Checkbox from '../../common/Checkbox';
import Report from '../../common/Report';
import SitesSelect from '../../common/SitesSelect';
import RadioGroup from '../../common/RadioGroup';
import StateMultipleSelect from '../../common/StateMultipleSelect';

class ProductSalesReport extends Component {

    constructor(props) {
        super(props);
        this.utils = new UtilsService();
        var initialState = {
            accountTypes: [],
            specialties: [],
            tags: [],
            accountTypeIds: "",
            productIds: "",
            beginDate: moment(new Date()).toDate(),
            endDate: moment(new Date()).toDate(),
            dateType: "FulfillDate",
            companyId: -1,
            accountTypeId: -1,
            stateIds: [],
            siteId: -1,
            specialtyId: -1,
            tagId: -1,
            customerNumber: '',
            summaryOnly: false,
            includePending: false,
            report: null,
            showInactive: false,
            groupByState: false,
        }
        this.sortBy = "";
        this.sortDirection = "";
        this.paramsKey = "params.product-sales-report";
        this.state = this.utils.prepareSessionState(initialState, this.paramsKey);

        this.http = new HttpRequestService();
        this.handleBeginDateChange = this.handleBeginDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.handleDateTypeChange = this.handleDateTypeChange.bind(this);
        this.handleAccountTypeChange = this.handleAccountTypeChange.bind(this);
        this.handleIncludePendingChange = this.handleIncludePendingChange.bind(this);
        this.handleStatesChange = this.handleStatesChange.bind(this);
        this.handleSiteChange = this.handleSiteChange.bind(this);
        this.handleSpecialtyChange = this.handleSpecialtyChange.bind(this);
        this.handleTagChange = this.handleTagChange.bind(this);
        this.handleSummaryOnlyChange = this.handleSummaryOnlyChange.bind(this);
        this.handleCustomerNumberChange = this.handleCustomerNumberChange.bind(this);
        this.handleShowInactiveChange = this.handleShowInactiveChange.bind(this);
        this.handleGroupByStateChange = this.handleGroupByStateChange.bind(this);
        this.hasAccountType = this.hasAccountType.bind(this);
        this.getAccountTypes = this.getAccountTypes.bind(this);
        this.getSpecialties = this.getSpecialties.bind(this);
        this.getTags = this.getTags.bind(this);
        this.getProductTree = this.getProductTree.bind(this);
        this.getReport = this.getReport.bind(this);
        this.sortReport = this.sortReport.bind(this);
    }

    componentDidMount() {
        this.getProductTree();
        this.getAccountTypes();
        this.getSpecialties();
        this.getTags();
        //this.getReport();
    }

    handleBeginDateChange(e) {
        this.setState({ beginDate: e });
    }

    handleEndDateChange(e) {
        this.setState({ endDate: e });
    }

    handleIncludePendingChange(checked) {
        this.setState({ includePending: checked });
    }

    handleDateTypeChange(value) {
        this.setState({
            dateType: value
        });
    }

    handleStatesChange(states) {
        this.setState({ stateIds: states });
    }

    handleCustomerNumberChange(e) {
        this.setState({ customerNumber: e.target.value });
    }

    handleSummaryOnlyChange(checked) {
        this.setState({ summaryOnly: checked });
    }

    handleGroupByStateChange(checked) {
        this.setState({ groupByState: checked });
    }

    handleShowInactiveChange(checked) {
        this.setState({ showInactive: checked }, this.getProductTree);
    }

    handleAccountTypeChange(checked, accountType) {
        var accountTypeIds = this.state.accountTypeIds;
        var arr = accountTypeIds !== "" ? accountTypeIds.split(",") : [];
        if (checked) {
            arr.push(accountType.key);
            this.setState({
                accountTypeIds: arr.length > 0 ? arr.join(",") : ""
            });
        }
        else {
            arr.splice(arr.findIndex(a => a === accountType.key), 1);
            this.setState({
                accountTypeIds: arr.length > 0 ? arr.join(",") : ""
            });
        }
    }

    handleSiteChange(siteId) {
        this.setState({ siteId: siteId });
    }

    handleSpecialtyChange(e) {
        this.setState({ specialtyId: e.target.value });
    }

    handleTagChange(e) {
        this.setState({ tagId: e.target.value });
    }

    hasAccountType(accountType) {
        var selectedAccountTypeIds = this.state.accountTypeIds.split(",");
        var result = selectedAccountTypeIds.find(a => a === accountType.key);
        return !!result;
    }

    getPrintPDFUrl(type, order) {
        return this.http.HOST + "/Print/Order?type=" + type + "&OrderID=" + order.orderID + "&OrderNumber=" + order.orderNumber;
    }

    getAccountTypes() {
        this.http.get('/api/Orders/GetAccountTypes', null).then(data => {
            if (this.state.accountTypeIds.length === 0) {
                var accountTypeIds = data.map(function (a) {
                    return a.key;
                });
                this.setState({
                    accountTypeIds: accountTypeIds.join(",")
                })
            }
            this.setState({ accountTypes: data });
        });
    }

    getSpecialties() {
        this.http.get('/api/Crm/GetUserDefinedField?id=8', null).then(data => {
            var specialties = data.options.map((o) => {
                return {
                    id: o.userDefinedFieldOptionID,
                    name: o.name
                }
            });
            this.setState({ specialties: specialties });
        });
    }

    getTags() {
        this.http.get('/api/Crm/GetUserDefinedField?id=2', null).then(data => {
            var tags = data.options.map((o) => {
                return {
                    id: o.userDefinedFieldOptionID,
                    name: o.name
                }
            });
            this.setState({ tags: tags });
        });
    }

    getProductTree() {
        var params = {
            activeOnly: !this.state.showInactive
        };
        this.http.get('/api/Inventory/GetProductTree', params).then(data => {
            var tree = data;
            var treeData = [];
            for (var i = 0; i < tree.companies.length; i++) {
                var company = tree.companies[i];
                var companyNode = {
                    id: "company_" + company.id,
                    text: company.companyName,
                    children: []
                };

                // Display Amazon and Resale categories combined
                const amazonProductType = company.productTypes.find(p => p.type === "Amazon");
                const resaleProductType = company.productTypes.find(p => p.type === "Resale");
                const amazonAndResaleCombined = amazonProductType && resaleProductType;
                if (amazonAndResaleCombined) {
                    var containerNode = {
                        id: companyNode.id + "_productType_" + "amazon_and_resale_container",
                        text: "Resale",
                        children: []
                    };

                    var amazonNode = {
                        id: companyNode.id + "_productType_" + amazonProductType.id,
                        text: amazonProductType.type,
                        children: []
                    }

                    for (var k = 0; k < amazonProductType.products.length; k++) {
                        var product = amazonProductType.products[k];
                        var productNode = {
                            id: product.id,
                            text: product.name,
                            icon: "jstree-file"
                        }

                        amazonNode.children.push(productNode);
                    }
                    containerNode.children.push(amazonNode);

                    var nonAmazonNode = {
                        id: companyNode.id + "_productType_" + resaleProductType.id,
                        text: "Non-Amazon",
                        children: []
                    }

                    for (var k = 0; k < resaleProductType.products.length; k++) {
                        var product = resaleProductType.products[k];
                        var productNode = {
                            id: product.id,
                            text: product.name,
                            icon: "jstree-file"
                        }

                        nonAmazonNode.children.push(productNode);
                    }
                    containerNode.children.push(nonAmazonNode);
                    companyNode.children.push(containerNode);
                }

                for (var j = 0; j < company.productTypes.length; j++) {
                    var productType = company.productTypes[j];
                    if (amazonAndResaleCombined && (productType.type === "Resale" || productType.type === "Amazon")) {
                        continue;
                    }

                    var productTypeNode = {
                        id: companyNode.id + "_productType_" + productType.id,
                        text: productType.type,
                        children: []
                    }

                    for (var k = 0; k < productType.products.length; k++) {
                        var product = productType.products[k];
                        var productNode = {
                            id: product.id,
                            text: product.name,
                            icon: "jstree-file"
                        }

                        productTypeNode.children.push(productNode);
                    }

                    companyNode.children.push(productTypeNode);
                }

                treeData.push(companyNode);
            }

            window.$("#productsTree").jstree("destroy");

            window.$('#productsTree').jstree({
                "core": {
                    "data": treeData,
                },
                "plugins": ["checkbox"],

            });

            setTimeout(() => {
                var selectedProducts = this.state.productIds.split(",");
                for (var i = 0; i < selectedProducts.length; i++) {
                    window.$("#productsTree").jstree('select_node', selectedProducts[i]);
                }
            }, 500);
        });
    }

    getReport(e) {
        if (!!e) {
            e.preventDefault();
        }
        var productIds = [];
        var data = window.$("#productsTree").jstree().get_selected();
        for (var i = 0; i < data.length; i++) {
            var parsed = parseInt(data[i]);
            if (!isNaN(parsed)) {
                productIds.push(data[i]);
            }
        }

        this.setState({
            productIds: productIds
        }, () => {

            var params = {
                beginDate: this.state.beginDate != null ? moment(this.state.beginDate).format('L') : "",
                endDate: this.state.endDate != null ? moment(this.state.endDate).format('L') : "",
                dateType: this.state.dateType,
                accountTypeIds: this.state.accountTypeIds,
                productIds: this.state.productIds.join(","),
                stateIds: this.state.stateIds.join(","),
                siteId: this.state.siteId,
                specialtyId: this.state.specialtyId,
                tagId: this.state.tagId,
                customerNumber: this.state.customerNumber,
                summaryOnly: this.state.summaryOnly,
                groupByState: this.state.groupByState,
                includePending: this.state.includePending,
                sortBy: this.sortBy,
                sortDirection: this.sortDirection
            }
            sessionStorage.setItem(this.paramsKey, JSON.stringify(params));
            this.http.get('/api/Report/GetProductSalesReport', params).then(data => {
                this.setState({ report: data });
            });
        })


    }

    sortReport(field) {
        if (this.sortBy !== field) {
            this.sortDirection = "ASC";
        }
        else {
            if (this.sortDirection === "ASC") {
                this.sortDirection = "DESC";
            }
            else {
                this.sortDirection = "ASC";
            }
        }
        this.sortBy = field;
        this.getReport();
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Product Sales Report</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={e => this.getReport(e)}>
                                    <div className="form-row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Date Type</label>
                                                <RadioGroup groupName="dateType" value={this.state.dateType} items={[{ value: "FulfillDate", text: "Fulfilled Date" }, { value: "EnteredDate", text: "Entered Date" }]} onChange={this.handleDateTypeChange}></RadioGroup>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="beginDate">Invoices From</label>
                                                <DatePicker className="form-control" selected={this.state.beginDate} onChange={this.handleBeginDateChange} />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="endDate">Invoices To</label>
                                                <DatePicker className="form-control" selected={this.state.endDate} onChange={this.handleEndDateChange} />
                                            </div>
                                            <div className="form-group">
                                                <Checkbox label="Include Pending Orders (Pending/Open/On Hold)" checked={this.state.includePending} onChange={this.handleIncludePendingChange}></Checkbox>
                                            </div>
                                            <div className="form-group">
                                                <label>State</label>
                                                <StateMultipleSelect countryID={211} value={this.state.stateIds} onChange={this.handleStatesChange}></StateMultipleSelect>
                                            </div>
                                            <div className="form-group">
                                                <label>Customer # (optional)</label>
                                                <input type="text" className="form-control" value={this.state.customerNumber} onChange={this.handleCustomerNumberChange} />
                                            </div>
                                            <div className="form-group">
                                                <label>AccountTypes</label>
                                                <div>
                                                    {this.state.accountTypes.map(accountType =>
                                                        <Checkbox style={{ display: "inline-block" }} key={accountType.key} label={accountType.value} checked={this.hasAccountType(accountType)} onChange={(checked) => this.handleAccountTypeChange(checked, accountType)}></Checkbox>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>Site</label>
                                                <SitesSelect value={this.state.siteId} onChange={this.handleSiteChange} hasAllOption={true}></SitesSelect>
                                            </div>
                                            <div className="form-group">
                                                <label>Specialty</label>
                                                <select className="form-control" value={this.state.specialtyId} onChange={this.handleSpecialtyChange}>
                                                    <option key="-1" value="-1">-- ALL --</option>
                                                    {this.state.specialties.map(specialty =>
                                                        <option key={specialty.id} value={specialty.id}>{specialty.name}</option>
                                                    )}
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label>Tag (Account)</label>
                                                <select className="form-control" value={this.state.tagId} onChange={this.handleTagChange}>
                                                    <option key="-1" value="-1">-- ALL --</option>
                                                    {this.state.tags.map(tag =>
                                                        <option key={tag.id} value={tag.id}>{tag.name}</option>
                                                    )}
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <Checkbox label="Group By State" checked={this.state.groupByState} onChange={this.handleGroupByStateChange}></Checkbox>
                                            </div>
                                            <div className="form-group">
                                                <Checkbox label="Summary Only" checked={this.state.summaryOnly} onChange={this.handleSummaryOnlyChange}></Checkbox>
                                            </div>

                                        </div>                                        
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Products</label>
                                                <Checkbox label="Show Inactive" checked={this.state.showInactive} onChange={this.handleShowInactiveChange}></Checkbox>
                                                <div id="productsTree"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row justify-content-end">
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        {this.state.report != null &&
                            <Report name="ProductSalesReport" hasData={this.state.report.items && this.state.report.items.length > 0} title="Product Sales Report" description={this.state.report.description}>
                                <div className="form-row">
                                    <table className="table table-striped mt-1 report-table">
                                        {!this.state.report.summaryOnly && !this.state.report.groupByState &&
                                            <React.Fragment>
                                                {this.state.report.items.map((group) =>
                                                    <React.Fragment key={group.name}>
                                                        <thead>
                                                            <tr className="bg-primary reportHeader">
                                                                <th colSpan={9}>{group.name}</th>
                                                            </tr>
                                                            <tr>
                                                                <th className="sortable" scope="col" onClick={(e) => this.sortReport("OrderNumber")}>INV #</th>
                                                                <th className="sortable" scope="col" onClick={(e) => this.sortReport(this.state.dateType)}>INV DATE</th>
                                                                <th className="sortable" scope="col" onClick={(e) => this.sortReport("CustomerNumber")}>CUST #</th>
                                                                <th className="sortable" scope="col" onClick={(e) => this.sortReport("CompanyName")}>CUSTOMER</th>
                                                                <th scope="col">ZIP</th>
                                                                <th className="sortable" scope="col" onClick={(e) => this.sortReport("StateAbbreviation")}>STATE</th>
                                                                <th className="sortable currency" scope="col" onClick={(e) => this.sortReport("ProductPrice")}>PRICE</th>
                                                                <th className="sortable currency" scope="col" onClick={(e) => this.sortReport("QuantityShipped")}>QTY</th>
                                                                <th className="sortable currency" scope="col" onClick={(e) => this.sortReport("CalculatedTotal")}>TOTAL</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {group.items && group.items.map((item, index) =>
                                                                <tr key={index}>
                                                                    <td><a href={this.getPrintPDFUrl("Invoice", item)} target="_blank" rel="noopener noreferrer">{item.orderNumber}</a></td>
                                                                    <td>{moment(this.state.dateType === "FulfillDate" ? item.fulfillDate : item.enteredDate).format('l')}</td>
                                                                    <td><Link to={"/customer/" + item.customerID}>{item.customerNumber}</Link></td>
                                                                    <td>{item.companyName}</td>
                                                                    <td>{item.zip}</td>
                                                                    <td>{item.stateAbbreviation}</td>
                                                                    <td className="currency">{this.utils.toCurrency(item.indProductPrice, item.currencyCode)}</td>
                                                                    <td className="currency">{item.quantityShipped}</td>
                                                                    <td className="currency">{this.utils.toCurrency(item.calculatedTotal, item.currencyCode)}</td>
                                                                </tr>
                                                            )}
                                                            {group.total.map((g, i) =>
                                                                <tr key={i} className="reportFooter">
                                                                    <th colSpan={6}></th>
                                                                    <th className="currency"></th>
                                                                    <th className="currency">{group.currencyQuantities[g.currency.toLowerCase()]}</th>
                                                                    <th className="currency">{this.utils.toCurrency(g.amount, g.currency)}</th>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </React.Fragment>
                                                )}
                                                <tbody>
                                                    {this.state.report.total.map((t, i) =>
                                                        <tr key={i} className="bg-dark reportFooter">
                                                            <th colSpan={6}></th>
                                                            <th className="currency"></th>
                                                            <th className="currency">{this.state.report.currencyQuantities[t.currency.toLowerCase()]}</th>
                                                            <th className="currency">{this.utils.toCurrency(t.amount, t.currency)}</th>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </React.Fragment>
                                        }
                                        {!this.state.report.summaryOnly && this.state.report.groupByState &&
                                            <React.Fragment>
                                                {this.state.report.stateItems.map((group) =>
                                                    <React.Fragment key={group.name}>
                                                        <thead>
                                                            <tr className="bg-primary reportHeader">
                                                                <th colSpan={3}>{group.name}</th>
                                                            </tr>
                                                            <tr>
                                                                <th className="sortable" scope="col" onClick={(e) => this.sortReport("ProductName")}>PRODUCT</th>
                                                                <th className="sortable currency" scope="col" onClick={(e) => this.sortReport("QuantityShipped")}>QTY</th>
                                                                <th className="sortable currency" scope="col" onClick={(e) => this.sortReport("CalculatedTotal")}>TOTAL</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {group.items && group.items.map((item, index) =>
                                                                <tr key={index}>
                                                                    <td>{item.productName}</td>
                                                                    <td className="currency">{item.quantityShipped}</td>
                                                                    <td className="currency">{this.utils.toCurrency(item.calculatedTotal, item.currencyCode)}</td>
                                                                </tr>
                                                            )}
                                                            {group.total.map((g, i) =>
                                                                <tr key={i} className="reportFooter">
                                                                    <th colSpan={1}></th>
                                                                    <th className="currency">{group.currencyQuantities[g.currency.toLowerCase()]}</th>
                                                                    <th className="currency">{this.utils.toCurrency(g.amount, g.currency)}</th>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </React.Fragment>
                                                )}
                                                <tbody>
                                                    {this.state.report.total.map((t, i) =>
                                                        <tr key={i} className="bg-dark reportFooter">
                                                            <th colSpan={1}></th>
                                                            <th className="currency">{this.state.report.currencyQuantities[t.currency.toLowerCase()]}</th>
                                                            <th className="currency">{this.utils.toCurrency(t.amount, t.currency)}</th>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </React.Fragment>
                                        }
                                        {this.state.report.summaryOnly &&
                                            <React.Fragment>
                                                <thead>
                                                    <tr>
                                                        <th scope="col">PRODUCT NAME</th>
                                                        <th className="currency" scope="col">QUANTITY</th>
                                                        <th className="currency" scope="col">PRICE</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.report.items.map(group =>
                                                        <React.Fragment>
                                                            {group.total.map((g, i) =>
                                                                <tr key={i}>
                                                                    <td>{group.name}</td>
                                                                    <td className="currency">{group.currencyQuantities[g.currency.toLowerCase()]}</td>
                                                                    <th className="currency">{this.utils.toCurrency(g.amount, g.currency)}</th>
                                                                </tr>
                                                            )}
                                                        </React.Fragment>
                                                    )}
                                                </tbody>
                                                <tbody>
                                                    {this.state.report.total.map((t, i) =>
                                                        <tr key={i} className="bg-dark reportFooter">
                                                            <th></th>
                                                            <th className="currency">{this.state.report.currencyQuantities[t.currency.toLowerCase()]}</th>
                                                            <th className="currency">{this.utils.toCurrency(t.amount, t.currency)}</th>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </React.Fragment>
                                        }
                                    </table>
                                </div>
                            </Report>
                        }
                    </div>
                </div>
            </div >
        );
    }
}

export default ProductSalesReport;
